import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import type { RootState } from './store';

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

// useAppDispatch is of type ThunkDispatch, in order to handle async.
// Something was lacking after converting the store to use a root reducer.
// https://stackoverflow.com/a/68343963/18844598
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
