/* eslint-disable import/no-cycle */
import { AxiosError } from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import { VariantType } from 'notistack';
import { push } from 'connected-react-router';
import { RootState } from '../../app/store';
import { logoutUser } from '../current-user/currentUserSlice';
import { logoutPartner } from '../current-partner/currentPartnerSlice';
import { ThunkAppDispatch } from '../../app/hooks';

interface Snack {
  id: string;
  message: string;
  variant: VariantType; // error / warning / info / success
}

interface Snacks {
  snacks: Snack[];
}

const initialState: Snacks = {
  snacks: [],
};

/**
 * Handle errors coming from axios responses and dispatch the appropriate slice
 *
 * @param error
 * @param dispatch
 * @returns
 */
export const handleErrors = function (
  error: AxiosError,
  dispatch: ThunkAppDispatch,
  resourceType: string = 'user'
) {
  // const dispatch = useAppDispatch();
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log('DEV ERROR LOG:', error, error?.response);
  }
  // Set a custom error, overriding the default
  // which is "timeout of 100ms exceeded" in this case
  if (error && error.code === 'ECONNABORTED') {
    dispatch(
      addSnack({
        message:
          'Could not connect to server -- check your internet connection',
        variant: 'error',
      })
    );
    return;
  }

  // Simply log the user out if they're forbidden from accessing
  // any resource (will this cause problems if we expose a privileged resource by accident?)
  if (error?.response?.status === 403) {
    if (error.response.headers['resource-type'] === 'user') {
      dispatch(logoutUser())
        .unwrap()
        .then(() => {
          dispatch(push('/'));
        });
    }
    dispatch(logoutPartner())
      .unwrap()
      .then(() => {
        dispatch(push('/'));
      });
    return;
  }

  // 404 via API should never happen, but we catch it here to
  // provide some kind of reasonable feedback.
  // This essentially amounts to a system error.
  if (error?.response?.status === 404 && !error?.response?.data?.errors) {
    dispatch(
      addSnack({
        message:
          "Oops, the data you're looking for unexpectedly doesn't exist!  " +
          'Drop us a line at admin@bookraid.com',
        variant: 'error',
      })
    );
    return;
  }

  if (
    error?.response?.status === 401 &&
    error?.response?.data?.errors.length >= 1
  ) {
    dispatch(
      addSnack({
        message: error.response.data.errors.join(', '),
        variant: 'error',
      })
    );
    return;
  }

  // 500 means there's something (hopefully temporarily) wrong with the server.
  if (error?.response?.status === 500) {
    dispatch(
      addSnack({
        message:
          'Oops, something went wrong with the server. Try refreshing the page. ' +
          "If that doesn't work, drop us a line at admin@bookraid.com",
        variant: 'error',
      })
    );
    return;
  }

  if (error?.response?.data?.errors?.base) {
    dispatch(
      addSnack({
        message: error.response.data.errors.base.join(', '),
        variant: 'error',
      })
    );
    return;
  }

  // Beyond this point, we're just handling form data validations.
  // For partners, errors will be shown on the form fields themselves,
  // rather than as snacks.
  if (resourceType === 'partner') {
    return;
  }

  if (
    error?.response?.data?.errors &&
    Array.isArray(error.response.data.errors)
  ) {
    error.response.data.errors.forEach((err: string) => {
      dispatch(addSnack({ message: err, variant: 'error' }));
    });
    return;
  }

  /* the standard error response is an object, such as:
  {
    email: ['not long enough', 'already taken'],
    full_messages: ['email not long enough', 'email already taken']
  }
  */
  error?.response?.data?.errors?.full_messages?.forEach((err: string) => {
    dispatch(addSnack({ message: err, variant: 'error' }));
  });
};

/**
 * Check the response to see if it's a string.
 * If so, snack out.
 *
 * The server will return a string under the following case:
 * - There's a 404, where the page is missing
 * - In routes.rb, there is a catch-all route:
 *     - `get '*path', to: 'statics#react_application'`
 * - In this case, it will return the react application layout page
 *
 * @param {any} response
 */
export const checkStringPayload = function (response: any) {
  if (typeof response !== 'string') return;
  // eslint-disable-next-line no-console
  console.error(
    'Woops, unexpectedly got HTML back from the server, check route catch-all'
  );
  throw new Error('Woops, our systems encountered a problem');
};

/** ******* MAIN SLICE ******* */
export const snacksSlice = createSlice({
  name: 'snacks',
  initialState,
  reducers: {
    clear: (state, action: any) => {
      state.snacks = state.snacks.filter(
        (snack) => snack.id !== action.payload
      );
    },
    addSnack: (state, action: any) => {
      const { message } = action.payload;
      const { variant } = action.payload;
      const id = (Math.random() + 1).toString(36).substring(7);

      if (message.trim() === '') {
        // eslint-disable-next-line no-console
        console.error('Empty message sent to snack display');
        return;
      }

      const snack: Snack = { message, variant, id };
      state.snacks.push(snack);
    },
  },
});

export const selectSnacks = (state: RootState) => state.snacks.snacks;

export const { addSnack, clear } = snacksSlice.actions;
export default snacksSlice.reducer;
