/**
 * Redirects
 */
export default [
  { from: '/affiliate-rewards', to: '/partners-referral-program' },
  { from: '/requirements', to: '/promotion-requirements' },
  { from: '/p/partners/sign_up', to: '/partners/sign-up' },
  { from: '/p/partners/sign_in', to: '/partners/sign-in' },
  { from: '/partners/sign_in', to: '/partners/sign-in' },
  { from: '/partner-with-us', to: '/partners' },
  { from: '/users/sign_in', to: '/users/sign-in' },
  { from: '/thank-you', to: '/' },
  { from: '/v2/about', to: '/about' },
  { from: '/v2/contact', to: '/contact' },
  { from: '/v2/partners', to: '/partners' },
  { from: '/v2/partners/account', to: '/partners/account' },
  { from: '/v2/partners/affiliate-rewards', to: '/partners-referral-program' },
  { from: '/v2/partners/books', to: '/partners/books' },
  { from: '/v2/partners/books/new', to: '/partners/books/new' },
  { from: '/v2/partners/dashboard', to: '/partners/dashboard' },
  { from: '/v2/partners/password/forgot', to: '/partners/password/forgot' },
  { from: '/v2/partners/promotions', to: '/partners/promotions' },
  { from: '/v2/partners/promotions/new', to: '/partners/promotions/new' },
  { from: '/v2/partners/sign_in', to: '/partners/sign-in' },
  { from: '/v2/partners/sign_up', to: '/partners/sign-up' },
  { from: '/v2/partnership-guidelines', to: '/partnership-guidelines' },
  { from: '/v2/pricing', to: '/pricing' },
  { from: '/v2/promotion-requirements', to: '/promotion-requirements' },
  { from: '/v2/terms', to: '/terms-and-conditions' },
  { from: '/ai/description', to: '/ai/book-description-generator' },
];
