import { utcToZonedTime, format } from 'date-fns-tz';
import { addDays, compareAsc, getHours } from 'date-fns';

/**
 * Newsletter is sent at 10am PST
 */
const hourNewsletterIsSent = 10;
const requiredDaysBetweenPromotions = 8 * 7; // 8 weeks times seven days
const newsletterSendTimezone = 'America/Vancouver';

/**
 * Add eight weeks to some date to determine the next
 * promotion date. This date should likely be computed
 * in the back-end, but is here for now.
 */
export const nextPossiblePromotionDate = (
  latestPromotionDate: string
): Date => {
  const lastPromotionDate = dateFromString(latestPromotionDate);
  return addDays(lastPromotionDate, requiredDaysBetweenPromotions);
};

/**
 * Given the last promotion date, check if this promotion
 * is promotable now.
 *  return 1 if the first date is after the second, -1 if the first
 *  date is before the second or 0 if dates are equal.
 */
export const isPromotableNow = (date: string): boolean => {
  const nextPromotableDate = nextPossiblePromotionDate(date);
  return compareAsc(new Date(), nextPromotableDate) > 0;
};

/**
 * Check if the requested date is too early for promotion.
 *
 * E.g. The requested date is '2022-06-15'
 *      The latest promotion date is '2022-05-01'
 *      The next possible promo date is '2022-07-01'
 */
export const isTooEarlyForPromotion = (
  requestedDate: Date,
  possiblePromotionDate: string
): boolean => {
  const nextPromotableDate = dateFromString(possiblePromotionDate);
  return compareAsc(requestedDate, nextPromotableDate) < 0;
};

/**
 * Check if the promotion is more than one day old
 *
 * @param promotionDate
 * @param possiblePromotionDate
 * @returns
 */
export const promotionHasExpired = (promotionDate: string): boolean => {
  const promotionExpiredDate = addDays(dateFromString(promotionDate), 1);
  return compareAsc(new Date(), promotionExpiredDate) > 0;
};

/**
 * This simple function checks to see if the time of day is
 * after 10am PST. If so, the next available date is tomorrow.
 *
 * Otherwise, the next available date is today.
 *
 * Algorithm:
 * - Get the date & time in PST.
 * - If PST time > 10am, return date + 1 day
 * - else, return date.
 */
/* eslint-disable-next-line import/prefer-default-export */
export const nextAvailableNewsletterDate = () => {
  let pstDatetime = utcToZonedTime(new Date(), newsletterSendTimezone);

  if (getHours(pstDatetime) >= hourNewsletterIsSent) {
    pstDatetime = addDays(pstDatetime, 1);
  }
  const datePart = format(pstDatetime, 'yyyy-MM-dd');
  return dateFromString(datePart);
};

/**
 * Check to see if we're close to the newsletter send time,
 * and the requested date is today.
 *
 * Algorithm:
 * - Check to see if it's after 10am. If so, return false. Not close
 * - Otherwise, return true if the requested send date is also
 *    today's date in PST. It's implied that it's also before 10am
 *    on that send date.
 */
export const isCloseToNewsletterSendTime = (sendDate: Date) => {
  const pstDatetime = utcToZonedTime(new Date(), newsletterSendTimezone);

  if (getHours(pstDatetime) >= hourNewsletterIsSent) return false;

  const pstDatePart = format(pstDatetime, 'yyyy-MM-dd');
  const sendDateDatePart = format(sendDate, 'yyyy-MM-dd');

  return pstDatePart === sendDateDatePart;
};

/**
 * When a date is given as a string like '2022-04-25', we
 * set it to a localized version of the date like 2022-04-25T00:00:00
 * for use in datepickers and such.
 *
 * Otherwise, attempting to create a new Date('2022-06-15')
 * results in a day backwards for anyone in a negative GMT timezone:
 * > new Date('2022-06-15')
 * > Tue Jun 14 2022 17:00:00 GMT-0700 (Pacific Daylight Time)
 *
 * > new Date('2022-06-15T00:00:00')
 * > Wed Jun 15 2022 00:00:00 GMT-0700 (Pacific Daylight Time)
 *
 * Also works with datetimes by converting directly
 *
 * @param string date
 * @returns Date
 */
export const dateFromString = (dateOrTime: string) => {
  if (dateOrTime.length > 10) {
    // it's a datetime
    return new Date(dateOrTime);
  }

  return new Date(`${dateOrTime}T00:00:00`);
};

// Format like "Thursday January 5th, 2023"
export const humanReadableDate = (date: Date | string) => {
  if (date instanceof Date) {
    return format(date, 'EEEE LLLL do, y');
  }

  return format(dateFromString(date), 'EEEE LLLL do, y');
};
