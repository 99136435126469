/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  checkStringPayload,
  handleErrors,
} from '../snacks/snacksSlice';

export interface PartnerNotHelpful {
  id: number;
  promotion_site_review_id: number;
  partner_id: number
  resourceType: string;
}

export interface NewPartnerNotHelpful {
  id?: number;
  promotion_site_review_id: number;
  resourceType: string;
}

export interface EditPartnerNotHelpful {
  id?: number;
  promotion_site_review_id: number;
  resourceType: string;
}

/**
 * Because we store reviewed and unreviewed partnerNotHelpfuls which
 * appear in separate lists, we have to sort based on reviewed_at
 * when they have been reviewed, and otherwise sort on requested_date.
 *
 * TODO CS - the sorting strategy depends on the request being made.
 * For example, the "Last 20 reviewed partnerNotHelpfuls" for users should
 * be sorted by reviewed_at DESC
 * By contrast, the partner's past partnerNotHelpfuls should be sorted by
 * the partnerNotHelpful date if it exists, or the requested date if the partnerNotHelpful
 * date wasn't set. It should have no notion of the requeted_at.
 * This divergence in strategies is accommodated for in our current architecture.
 */
const partnerNotHelpfulsAdapter = createEntityAdapter<PartnerNotHelpful>();

export const createPartnerNotHelpful = createAsyncThunk<
  void, // Return type of the payload creator
  NewPartnerNotHelpful, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('partnerNotHelpfuls/create', async (partnerNotHelpful: NewPartnerNotHelpful, thunkApi: any) => {
  const headers = { 'resource-type': partnerNotHelpful.resourceType };

  try {
    const response = await axiosInstance.post('/api/v1/partner_not_helpfuls', partnerNotHelpful, {
      headers,
    });
    thunkApi.dispatch(partnerNotHelpfulsSlice.actions.partnerNotHelpfulReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, partnerNotHelpful.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const partnerNotHelpfulsSlice = createSlice({
  name: 'partnerNotHelpfuls',
  initialState: partnerNotHelpfulsAdapter.getInitialState({}),
  reducers: {
    /**
     * A partnerNotHelpful was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * partnerNotHelpfuls doesn't still show the partnerNotHelpful whose status changed.
     */
    partnerNotHelpfulChanged: (state, action: PayloadAction<number>) => {
      partnerNotHelpfulsAdapter.removeOne(state, action);
    },
    partnerNotHelpfulsReceivedReplace: (state, action: PayloadAction<PartnerNotHelpful[]>) => {
      checkStringPayload(action.payload);
      partnerNotHelpfulsAdapter.setAll(state, action.payload);
    },
    partnerNotHelpfulsReceivedAdd: (state, action: PayloadAction<PartnerNotHelpful[]>) => {
      checkStringPayload(action.payload);
      partnerNotHelpfulsAdapter.upsertMany(state, action.payload);
    },
    partnerNotHelpfulReceived: (state, action: PayloadAction<PartnerNotHelpful>) => {
      partnerNotHelpfulsAdapter.upsertOne(state, action.payload);
    },
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = partnerNotHelpfulsAdapter.getSelectors<RootState>(
  (state) => state.partnerNotHelpfuls
);
export const {
  selectAll: selectAllPartnerNotHelpfuls,
  selectById: selectPartnerNotHelpfulById,
} = selectors;

export default partnerNotHelpfulsSlice.reducer;
