/**
 * IMPORTANT!
 *
 * This error boundary is written as a class because React does not
 * yet support `componentDidCatch` as a hook.
 * https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes
 *
 * This class copied and modified from here:
 * https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/error_boundaries/#options-2-writing-your-custom-error-boundary-component
 *
 * Here's a simple way to test this functionality:
 * 1. Open `dates.ts` and go to the `nextAvailableNewsletterDate` function
 * 2. Change the date format from `YYYY` to `yyyy`
 * 3. Visit the `partners/promotions/new` page.
 * 4. Witness the error page.
 */

import React, { Component, /** ErrorInfo, */ ReactNode } from 'react';
import { Typography } from '@mui/material';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class AppErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // Not sure if this is good transparency, or noise.
  // Seems like noise, often complaining about a "cross-origin error"
  // which is only a development problem anyway.
  // The actual causes of the errors seem to be properly logged.
  //
  // TODO CS / TODO SM Add error logging to some back-end here?
  //
  // public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
  //   console.error('Uncaught error:', error, errorInfo);
  // }

  public render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Woops! Something&apos;s amiss
          </Typography>

          <Typography paragraph>
            If going back or refreshing doesn&apos;t work, please drop us a line
            at <strong>admin@bookraid.com</strong> to let us know!
          </Typography>
          <Typography paragraph>
            We&apos;ll have you up and running in no time.
          </Typography>
        </>
      );
    }

    return children;
  }
}

export default AppErrorBoundary;
