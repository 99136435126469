/* eslint-disable import/no-cycle */
// Creates a slice for the user.
// "This API is the standard approach for writing Redux logic"
// - https://redux-toolkit.js.org/api/createSlice

import Cookies from 'universal-cookie';
import { push } from 'connected-react-router';
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  RootState,
  // AppThunk
} from '../../app/store';
import axiosInstance, { axios } from '../../utils/axios';
import {
  clearCredentialsCookie,
  hasCredentials,
} from '../../utils/axios/middleware';
import { addSnack, handleErrors } from '../snacks/snacksSlice';

export interface AuthHeaders {
  uid: string;
  'access-token': string;
  client: string;
  'resource-type': string;
}

export interface CurrentUser {
  id: number;
  email: string;
  signInError: string;
  isLoading: boolean;
  isSignedIn: boolean;
}

export interface Error {
  errorMessage: string;
}

export interface Credentials {
  email: string;
  password: string;
}

const cookies = new Cookies();

const currentUserAdapter = createEntityAdapter<CurrentUser>({
  sortComparer: (a: CurrentUser, b: CurrentUser) => b.id - a.id,
});

// Getters - used in the front end to automatically reflect changes
// that occur in the store's reducers.
export const isLoggedInUser = (state: RootState) =>
  cookies.get('user') &&
  Object.keys(cookies.get('user')).length > 0 &&
  state.currentUser.isSignedIn;

// The function below is a "thunk" and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(findUser(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const signInUser = createAsyncThunk<
  any, // Return type of the payload creator
  Credentials, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('user/signIn', async (credentials: Credentials, thunkApi: any) => {
  // Comment these in to sign in without entering credentials each time.
  // Speeds development up significantly.
  // credentials.email = 'coreysan13@gmail.com';
  // credentials.password = 'password';

  if (credentials.email.trim() === '') {
    thunkApi.dispatch(
      addSnack({ message: 'Please enter an email address', variant: 'error' })
    );
  }
  if (credentials.password.trim() === '') {
    thunkApi.dispatch(
      addSnack({ message: 'Please enter a password', variant: 'error' })
    );
  }

  try {
    const response = await axiosInstance.post(
      '/api/v1/user-auth/sign_in',
      {
        ...credentials,
        withCredentials: true,
      },
      { headers: { 'resource-type': 'user' } }
    );

    const headers = {
      client: response.headers.client,
      uid: response.headers.uid,
      'access-token': response.headers['access-token'],
      'resource-type': 'user',
    };

    await axiosInstance.get('/api/v1/user-auth/validate_token', { headers });
    thunkApi.dispatch(
      currentUserSlice.actions.currentUserChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    clearCredentialsCookie('user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/**
 * For non-authenticated pages, we still attempt to validate
 * the user using this action.
 *
 * This is so that we can show logged-in information on the
 * (mostly) static pages.
 *
 * This differs from the `validateFromCookieUser` function
 * in that we don't sign them out or show any error snack on failure.
 */
export const attemptUserValidation = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('partner/validateFromCookie', async (nothing, thunkApi: any) => {
  const headers: AuthHeaders = cookies.get('user');

  if (!headers) {
    return thunkApi.rejectWithValue();
  }

  const authHeaders = { 'resource-type': 'user' };
  try {
    const response = await axiosInstance.get(
      '/api/v1/user-auth/validate_token',
      {
        headers: authHeaders,
      }
    );

    thunkApi.dispatch(
      currentUserSlice.actions.currentUserChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    clearCredentialsCookie('user');
    thunkApi.dispatch(currentUserSlice.actions.signOut());
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const validateFromCookieUser = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('user/validateFromCookie', async (nothing, thunkApi: any) => {
  const headers: AuthHeaders = cookies.get('user');
  if (!headers) {
    thunkApi.dispatch(
      addSnack({
        message:
          "It looks like you're not signed in. Please sign in before continuing.",
        variant: 'error',
      })
    );
    thunkApi.dispatch(push('/users/sign-in'));
    return thunkApi.rejectWithValue();
  }

  try {
    const response = await axiosInstance.get(
      '/api/v1/user-auth/validate_token',
      {
        headers: { 'resource-type': 'user' },
      }
    );

    thunkApi.dispatch(
      currentUserSlice.actions.currentUserChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    clearCredentialsCookie('user');
    thunkApi.dispatch(currentUserSlice.actions.signOut());
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/**
 * Log the user out by:
 *   - submitting a delete request to the BR session auth
 *   - removing cookies
 *   - Redirecting back to the sign-in page
 */
export const logoutUser = createAsyncThunk(
  'user/logout',
  async (params, thunkApi: any) => {
    if (!hasCredentials('user')) {
      thunkApi.dispatch(push('/users/sign-in'));
      return thunkApi.rejectWithValue();
    }

    try {
      const response = await axiosInstance.delete(
        '/api/v1/user-auth/sign_out',
        {
          headers: { 'resource-type': 'user' },
        }
      );
      clearCredentialsCookie('user');
      thunkApi.dispatch(currentUserSlice.actions.signOut());
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      // eslint-disable-next-line no-console
      console.error('Failed to log out');
      // eslint-disable-next-line no-console
      console.error(error);
      handleErrors(error, thunkApi.dispatch, 'user');
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/**
 * USER SLICE
 */
export const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: currentUserAdapter.getInitialState({
    email: '',
    signInError: '',
    isLoading: false,
    isSignedIn: false,
  }),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    signOut: (state) => {
      state.isSignedIn = false;
    },
    currentUserChanged: (state, action: PayloadAction<CurrentUser>) => {
      currentUserAdapter.upsertOne(state, action.payload);
      state.isSignedIn = true;
    },
  },
});

const selectors = currentUserAdapter.getSelectors<RootState>(
  (state) => state.currentUser
);
export const { selectAll: selectCurrentUser } = selectors;

export default currentUserSlice.reducer;
