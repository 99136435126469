/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import { checkStringPayload, handleErrors } from '../snacks/snacksSlice';

export interface DiscountCode {
  id?: number;
  amount_off_in_cents?: number | null;
  percent_off?: number | null;
  code: string;
  is_only_new_books: boolean;
  is_only_recapture_partners: boolean;
  is_only_recapture_new_partners: boolean;
  number_of_uses: number | null; // aka the max number set by admins
  num_times_used: number;
  created_at: string;
  updated_at: string;
}

export type DiscountCodeSave = Omit<
  DiscountCode,
  'created_at' | 'updated_at' | 'num_times_used'
>;

interface DiscountCodeParams {
  discountCode: string;
  bookId: number;
  partnerId: number;
}

const discountsAdapter = createEntityAdapter<DiscountCode>();

export const getAllDiscounts = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('discounts/all', async (nothing, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.get('/api/v1/discounts', {
      headers,
    });
    thunkApi.dispatch(
      discountsSlice.actions.discountsReceived(response.data.discounts)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getOneDiscount = createAsyncThunk<
  void, // Return type of the payload creator
  number, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('discounts/get', async (discountId, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.get(
      `/api/v1/discounts/${discountId}`,
      {
        headers,
      }
    );
    thunkApi.dispatch(discountsSlice.actions.discountReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const save = createAsyncThunk<
  void, // Return type of the payload creator
  DiscountCodeSave, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('discounts/save', async (discount: DiscountCodeSave, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.post(`/api/v1/discounts`, discount, {
      headers,
    });

    thunkApi.dispatch(discountsSlice.actions.discountReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const update = createAsyncThunk<
  void, // Return type of the payload creator
  DiscountCodeSave, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('discounts/update', async (discount: DiscountCodeSave, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.patch(
      `/api/v1/discounts/${discount.id}`,
      discount,
      { headers }
    );

    thunkApi.dispatch(discountsSlice.actions.discountReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const destroy = createAsyncThunk<
  void, // Return type of the payload creator
  number, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('discounts/update', async (discountId, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.delete(
      `/api/v1/discounts/${discountId}`,
      {
        headers,
      }
    );
    thunkApi.dispatch(discountsSlice.actions.discountRemoved(discountId));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const isValid = createAsyncThunk<
  void, // Return type of the payload creator
  DiscountCodeParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>(
  'discounts/is-valid',
  async (discountCodeCheck: DiscountCodeParams, thunkApi: any) => {
    try {
      const { bookId, partnerId, discountCode } = discountCodeCheck;
      const response = await axiosInstance.get(
        `/api/v1/discounts/validate/${discountCode}?book_id=${bookId}&partner_id=${partnerId}`
      );
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch);
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

export const discountsSlice = createSlice({
  name: 'discounts',
  initialState: discountsAdapter.getInitialState({}),
  reducers: {
    discountReceived: (state, action: PayloadAction<DiscountCode>) => {
      checkStringPayload(action.payload);
      discountsAdapter.upsertOne(state, action.payload);
    },
    discountsReceived: (state, action: PayloadAction<DiscountCode[]>) => {
      checkStringPayload(action.payload);
      discountsAdapter.setAll(state, action.payload);
    },
    discountRemoved: (state, discountId: PayloadAction<number>) => {
      discountsAdapter.removeOne(state, discountId);
    },
  },
});

const selectors = discountsAdapter.getSelectors<RootState>(
  (state) => state.discounts
);
export const { selectAll: selectAllDiscounts, selectById: selectDiscountById } =
  selectors;

export default discountsSlice.reducer;
