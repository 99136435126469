/* eslint-disable import/no-cycle */
import { AxiosError } from 'axios';
import Cookies from 'universal-cookie';
import axiosInstance from './index';
import { AuthHeaders } from '../../store/current-user/currentUserSlice';

/**
 * Before a request is sent, attach the credentials
 * of the pertinent resource type (user || partner)
 *
 * The use of this middleware requires that the resource-type
 * header is set to either 'user' or 'partner'
 */
export const initAxiosRequestMiddleware = function () {
  axiosInstance.interceptors.request.use(
    (config) => {
      // if an access token is already attached, don't attach more from cookies
      // this is really only for the password reset flow, which requires passing through tokens
      if (config.headers['access-token']) {
        return config;
      }

      if (!config.headers['resource-type']) {
        return config;
      }

      // If a resource type was set, add the cookie credentials
      // to the new config's headers
      const cookies = new Cookies();

      // Logging kept here for future debugging
      // console.log('Setting credentials headers');
      // console.log('cookies.get(config.headers[resource-type]): ', cookies.get(config.headers['resource-type']));

      config.headers = {
        ...config.headers,
        ...cookies.get(config.headers['resource-type']),
      };

      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );
};

/**
 * On each response, set the credentials back into the cookie.
 * This will be especially useful if...
 * config.change_headers_on_each_request is ever set to true.
 *
 * For now, this may only be useful after signing in. Theoretically,
 * we could update the logic here to run only after
 * successful sign-in or credential validation.
 */
export const initAxiosResponseMiddleware = function () {
  axiosInstance.interceptors.response.use(
    (response) => {
      // If there's access token in the response headers,
      // save the header credentials to cookies
      if (response?.headers?.['access-token']) {
        const creds = {
          client: response.headers.client,
          uid: response.headers.uid,
          'access-token': response.headers['access-token'],
          'resource-type': response.headers['resource-type'],
        };

        // Kept in for debugging
        // console.log('Setting credentials cookie');
        // console.log('creds: ', creds);

        setCredentialsCookie(creds);
      }
      return response;
    },
    // Setting these in the error is really only important
    // if we set chat_headers_on_each_request to true.
    (error) => {
      if (error?.response?.headers?.['access-token']) {
        const creds = {
          client: error.response.headers.client,
          uid: error.response.headers.uid,
          'access-token': error.response.headers['access-token'],
          'resource-type': error.response.headers['resource-type'],
        };
        setCredentialsCookie(creds);
      }
      return Promise.reject(error);
    }
  );
};

/**
 * Set the cookie for partner or user, depending on the
 * resource type given.
 *
 * @param headers
 */
export function setCredentialsCookie(headers: AuthHeaders) {
  const cookies = new Cookies();
  cookies.set(headers['resource-type'], JSON.stringify(headers), { path: '/' });
}

/**
 * Check if there are credentials for this role by asserting
 * that a uid, access token, and client have been set.
 *
 * Note that this doesn't check the validity of the credentials,
 * just that they're set to some truthy values.
 */
export function hasCredentials(resourceType: string) {
  const cookies = new Cookies();
  const credentials = cookies.get(resourceType);
  if (!credentials) return false;

  return (
    !!credentials?.uid && !!credentials.client && !!credentials['access-token']
  );
}

/**
 * Find partner or user credentials, and return them.
 * If no credentials are found, return null.
 */
export function findCredentials() {
  const cookies = new Cookies();
  let creds: any = null;

  ['partner', 'user'].forEach((cookieName) => {
    if (creds) { return };

    const tempCreds = cookies.get(cookieName);
    if (tempCreds && tempCreds['access-token']) {
      tempCreds['resource-type'] = cookieName;
      creds = tempCreds;
    }
  });

  return creds;
}

/**
 * Clear the credential cookie if there's a resource type to
 * clear it for.
 *
 * Note that if no cookies exist at all (user tries accessing an
 * internal page in a new browser), there won't be a resource type
 * and therefore we won't have any cookie to clear.
 *
 * @param resourceType
 */
export function clearCredentialsCookie(resourceType: string) {
  if (!resourceType) return;

  const cookies = new Cookies();
  cookies.remove(resourceType, { path: '/' });
  cookies.remove('_bookraid_session', { path: '/' });
}
