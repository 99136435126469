import React from 'react';
import {
  CircularProgress,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  List,
} from '@mui/material';
import ExpandIcon from '@mui/icons-material/Expand';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getNewsletterPromoCounts,
  selectNewsletterPromoCountById,
  NewsletterPromotionCount,
} from '../../store/newsletters/newsletterPromoCountsSlice';
import NewsletterSidebarItem from './NewsletterSidebarItem';
import theme from '../../theme/theme';
import constants from '../../theme/constants';

const classes = {
  // Beware, there's undry code here. These styles also appear
  // in Sidebar.tsx
  icon: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minWidth: constants.sidebar.expanded.iconWidth,
  },
  iconCollapsed: {
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    minWidth: constants.sidebar.collapsed.iconWidth,
  },
  insetListItem: {
    pl: 4,
  },
};

/**
 * We purposely use an invalid category ID here, as
 * all we care about are the totals.
 */
const invalidCategoryId = 0;

/**
 * Cycle between newsletter expansions of zero, 1, or 2
 * 0. Closed (0)
 * 1. Half (250)
 * 2. Fully open (500)
 */
const newsletterExpansionOptions = 3;
const newsletterExpansionHeight = 250;

const NewslettersSidebarItems = function ({
  sidebarOpen,
}: {
  sidebarOpen: boolean;
}) {
  const dispatch = useAppDispatch();
  const [loadingNewsletters, setLoadingNewsletters] = React.useState(false);
  const [newsletterExpansionIndex, setNewslettersExpansion] = React.useState(0);
  const categoryCounts = useAppSelector((state) =>
    selectNewsletterPromoCountById(state, invalidCategoryId)
  );

  React.useEffect(() => {
    setLoadingNewsletters(true);
    dispatch(getNewsletterPromoCounts(invalidCategoryId)).finally(() =>
      setLoadingNewsletters(false)
    );
    return () => setLoadingNewsletters(false);
  }, []);

  const newslettersListMaxHeight = `${
    newsletterExpansionIndex * newsletterExpansionHeight
  }px`;

  return (
    <>
      <ListItem
        sx={{ p: 0 }}
        onClick={() =>
          setNewslettersExpansion(
            (newsletterExpansionIndex + 1) % newsletterExpansionOptions
          )
        }
        secondaryAction={
          !sidebarOpen ? null : (
            <IconButton edge="end" aria-label="comments" sx={{ color: '#ccc' }}>
              {loadingNewsletters && !categoryCounts ? (
                <CircularProgress size={20} sx={{ color: '#ccc' }} />
              ) : (
                <ExpandIcon />
              )}
            </IconButton>
          )
        }
      >
        <ListItemButton>
          <ListItemIcon sx={sidebarOpen ? classes.icon : classes.iconCollapsed}>
            <NewspaperIcon />
          </ListItemIcon>
          <ListItemText primary="Newsletters" />
        </ListItemButton>
      </ListItem>

      <List sx={{ height: newslettersListMaxHeight, p: 0, overflow: 'scroll' }}>
        <ListItemButton
          sx={sidebarOpen ? classes.insetListItem : null}
          component={Link}
          to="/users/newsletters"
        >
          {!sidebarOpen ? (
            <HistoryToggleOffIcon />
          ) : (
            <ListItemText color="text.secondary" primary="Past Newsletters" />
          )}
        </ListItemButton>
        {categoryCounts?.newsletter_promotion_counts.map(
          (newsletterPromotionCount: NewsletterPromotionCount) => (
            <NewsletterSidebarItem
              key={newsletterPromotionCount.newsletter_date}
              newsletterPromotionCount={newsletterPromotionCount}
              sidebarOpen={sidebarOpen}
            />
          )
        )}
      </List>
      <Divider />
    </>
  );
};

export default NewslettersSidebarItems;
