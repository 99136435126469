import { useEffect } from 'react';
import FacebookPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA from 'react-ga4';
import ReactPinterestTag from 'react-pinterest-tag';

const TrackingPixels = function () {
  useEffect(() => {
    FacebookPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL as string);
    RedditPixel.init(process.env.REACT_APP_REDDIT_ADVERTISER_ID as string);
    ReactPinterestTag.init(process.env.REACT_APP_PINTEREST_TAG as string);
    ReactGA.initialize(
      process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_ID as string
    );
    Sentry.init({
      dsn: 'https://b12b708cdc9a46aca829c1e3c9a45a0a@o1244075.ingest.sentry.io/6400325',
      integrations: [new BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }, []);

  return null;
};

export default TrackingPixels;
