import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * On first visit (new tab), react will default to showing
 * the page from the top, even when an anchor tag is present.
 * On a refresh, react will show the last viewport, again
 * regardless of the anchor tag.
 *
 * To circumvent this, we change the scrollRestoration to manual.
 * If no hash is present, then we scroll to the top of the page.
 *
 * If a hash IS present, then we scroll to that element.
 *
 */
export default function ScrollToTop() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      const interval = setInterval(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          clearInterval(interval);
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return null;
}
