import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BookIcon from '@mui/icons-material/Book';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventNoteIcon from '@mui/icons-material/EventNote';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { People, PersonAdd } from '@mui/icons-material';
import { MenuItemType } from './menuItemsType';

const parterMenuItems: MenuItemType[] = [
  {
    url: '/partners/dashboard',
    icon: DashboardIcon,
    text: 'My Dashboard',
    withDivider: true,
    displayInDesktopMenu: true,
  },
  {
    url: '/partners/books',
    icon: BookIcon,
    text: 'My eBooks',
    displayInDesktopMenu: false,
  },
  {
    url: '/partners/books/new',
    icon: AddCircleIcon,
    text: 'Add an eBook',
    // If we run into mobile nav space issues, these could hidden on mobile
    // displayInMobile: false,
    withDivider: true,
    displayInDesktopMenu: false,
  },
  {
    url: '/partners/promotions',
    icon: EventNoteIcon,
    text: 'My Promotions',
    displayInDesktopMenu: false,
  },
  {
    url: '/partners/promotions/new',
    icon: EventAvailableIcon,
    text: 'Add a Promotion',
    // If we run into mobile nav space issues, these could hidden on mobile
    // displayInMobile: false,
    withDivider: true,
    displayInDesktopMenu: false,
  },
  {
    url: '/partners/author-profiles',
    icon: People,
    text: 'My Author Profiles',
    displayInDesktopMenu: false,
  },
  {
    url: '/partners/author-profiles/new',
    icon: PersonAdd,
    text: 'Add an Author Profile',
    // If we run into mobile nav space issues, these could hidden on mobile
    // displayInMobile: false,
    withDivider: true,
    displayInDesktopMenu: false,
  },
  {
    url: '/partners/account',
    icon: ManageAccountsIcon,
    text: 'Edit Account',
    displayInDesktopMenu: true,
  },
  {
    url: '/partners-referral-program',
    icon: AttachMoneyIcon,
    text: 'Referral Program',
    displayInDesktopMenu: false,
  },
];

export default parterMenuItems;
