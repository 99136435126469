import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from '@mui/material';
import redirects from './redirects';
import PublicRoutes from './Routes/PublicRoutes';
import PublicPartnerRoutes from './Routes/PublicPartnerRoutes';
import SuspenseLoader from './SuspenseLoader';

const NoMatch = React.lazy(() => import('../../statics/NoMatch'));

const UserSignIn = React.lazy(() => import('../../components/user/SignIn'));

const InternalUserRoutes = React.lazy(
  () => import('./Routes/InternalUserRoutes')
);

const PartnersLandingPage = React.lazy(
  () => import('../../statics/partners-landing-page/PartnersLandingPage')
);

const InternalPartnerRoutes = React.lazy(
  () => import('./Routes/InternalPartnerRoutes')
);

const Router = function () {
  return (
    <Switch>
      {redirects.map((redirect) => (
        <Redirect
          key={`${redirect.from}${redirect.to}`}
          from={redirect.from}
          to={redirect.to}
        />
      ))}

      <Route path="/users/sign-in" exact>
        <Suspense fallback={<SuspenseLoader />}>
          <UserSignIn />
        </Suspense>
      </Route>

      <Route path="/users/*">
        <Suspense fallback={<SuspenseLoader />}>
          <InternalUserRoutes />
        </Suspense>
      </Route>

      <Route path="/partners/*" exact>
        <PublicPartnerRoutes />
        <Suspense fallback={<SuspenseLoader />}>
          <InternalPartnerRoutes />
        </Suspense>
      </Route>
      <Route path="/partners" exact>
        <Suspense fallback={<SuspenseLoader />}>
          <PartnersLandingPage />
        </Suspense>
      </Route>

      <PublicRoutes />

      <Route path="*">
        <Container maxWidth="xl">
          <Suspense fallback={<SuspenseLoader />}>
            {React.createElement(NoMatch, {})}
          </Suspense>
        </Container>
      </Route>
    </Switch>
  );
};

export default Router;
