/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axiosInstance, { axios } from '../../utils/axios';

import { addSnack, checkStringPayload, handleErrors } from '../snacks/snacksSlice';
import { Book } from '../books/booksSlice';

export interface Partner {
  id: number | null;
  name: string;
  email: string;
}

export interface Author {
  id?: number | null;
  description?: string;
  partner?: Partner | null;
  partner_id?: number | null;
  books?: Array<Book> | null;
  books_count?: number;
  name: string;
  avatar?: File | null;
  avatar_url?: string;
  avatar_mini_url?: string;
  website?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  created_at?: Date;
  updated_at?: Date;
}

interface GenericApiParams {
  id?: number;
  resourceType?: string;
  public?: boolean;
  query?: string;
  joins?: Array<any>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
}

export interface UpdateParams {
  resourceType: string;
  id: number;
  author: Author;
  genericParams: GenericApiParams;
}

const authorsAdapter = createEntityAdapter<Author>({});

export const getAuthor = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('authors/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(`/api/v1/authors/${params.id}`, {
      headers: { 'resource-type': params.resourceType },
      params: {
        public: params.public,
        joins: params.joins,
      },
    });
    thunkApi.dispatch(authorsSlice.actions.authorReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getAllAuthors = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('authors/getAll', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get('/api/v1/authors', {
      params,
      headers: { 'resource-type': params.resourceType },
    });
    thunkApi.dispatch(authorsSlice.actions.authorsReceived(response.data.authors));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updateAuthor = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('author/update', async (params: UpdateParams, thunkApi: any) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/authors/${params.id}/`,
      params.author,
      {
        headers: { 'resource-type': params.resourceType },
        params: params.genericParams,
      }
    );
    thunkApi.dispatch(authorsSlice.actions.authorReceived(response.data));
    return response.data;
  } catch (error) {
    // If it's no an axios error or if we're a partner, just
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});


export const createAuthor = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('author/create', async (author: Author, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.post(`/api/v1/authors`, author, {
      headers,
    });
    thunkApi.dispatch(authorsSlice.actions.authorReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const deleteAuthor = createAsyncThunk<
  void, // Return type of the payload creator
  number, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('authors/create', async (authorId: number, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.delete(
      `/api/v1/authors/${authorId}`,
      {
        headers,
      }
    );
    thunkApi.dispatch(authorsSlice.actions.authorRemoved(authorId));
    thunkApi.dispatch(
      addSnack({
        message: 'Author Profile was successfully deleted.',
        variant: 'success',
      })
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const authorsSlice = createSlice({
  name: 'author',
  initialState: authorsAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    authorReceived: (state, action: PayloadAction<Author>) => {
      checkStringPayload(action.payload);
      authorsAdapter.upsertOne(state, action.payload);
    },
    authorsReceived: (state, action: PayloadAction<Author[]>) => {
      checkStringPayload(action.payload);
      authorsAdapter.setAll(state, action.payload);
    },
    authorRemoved: (state, action: PayloadAction<number>) => {
      authorsAdapter.removeOne(state, action);
    },
  },
});

const selectors = authorsAdapter.getSelectors<RootState>((state) => state.authors);
export const { selectById: selectAuthor, selectAll: selectAllAuthors } = selectors;

export default authorsSlice.reducer;
