import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const SuspenseLoader = function () {
  return (
    <Box sx={{ textAlign: 'center', py: 10 }}>
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default SuspenseLoader;
