const constants = {
  maxPromoCostInDollars: 60,

  resultsPerPage: 20,

  minPasswordLength: 8,

  navbar: {
    height: 86,
    mobileHeight: 86 + 50,
    border: {
      height: 15,
    },
  },
  sidebar: {
    expanded: { width: '15rem', iconWidth: '36px', md: { width: '12.4rem' } }, // rem to fit text of various zooms
    collapsed: { width: 'calc(1rem + 42px)', iconWidth: '56px' }, // 1rem for the icon, plus 42 for padding
    transitionTime: '500ms',
  },
};

export default constants;
