import React from 'react';
import {
  ListItemButton,
  ListItemText,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

import { NewsletterPromotionCount } from '../../store/newsletters/newsletterPromoCountsSlice';
import { dateFromString } from '../../utils/dates';

const classes = {
  insetListItem: {
    pl: 4,
  },
};

const NewsletterSidebarItem = function ({
  sidebarOpen,
  newsletterPromotionCount,
}: {
  sidebarOpen: boolean;
  newsletterPromotionCount: NewsletterPromotionCount;
}) {
  const toUrl = `/users/newsletters/${newsletterPromotionCount.id}`;

  if (!sidebarOpen) {
    // convert to a date like '12.31'
    const dateText = newsletterPromotionCount.newsletter_date
      .substring(5)
      .replace('-', '.');
    return (
      <ListItemButton
        key={newsletterPromotionCount.newsletter_date}
        component={Link}
        to={toUrl}
        sx={{ p: 0 }}
      >
        <ListItemText
          color="text.secondary"
          sx={{ textAlign: 'center' }}
          primary={dateText}
        />
      </ListItemButton>
    );
  }

  const newsletterSendDate = dateFromString(
    newsletterPromotionCount.newsletter_date
  );

  const dayOfWeek = format(newsletterSendDate, 'EEE');
  const dateString = format(newsletterSendDate, 'LLL do');
  const numPromotions = newsletterPromotionCount.promotions_in_total;
  return (
    <ListItemButton
      key={newsletterPromotionCount.id}
      sx={classes.insetListItem}
      component={Link}
      to={toUrl}
    >
      <Table padding="none">
        <TableBody>
          <TableRow>
            <TableCell sx={{ borderBottom: 0, width: '3em' }} align="left">
              {dayOfWeek}
            </TableCell>
            <TableCell sx={{ borderBottom: 0 }} align="left">
              {dateString}
            </TableCell>
            <TableCell sx={{ borderBottom: 0 }} align="right">
              {numPromotions}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </ListItemButton>
  );
};

export default NewsletterSidebarItem;
