/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  checkStringPayload,
  handleErrors,
} from '../snacks/snacksSlice';
import { createPartnerHelpful } from '../partner-helpfuls/partnerHelpfulsSlice';
import { createPartnerNotHelpful } from '../partner-not-helpfuls/partnerNotHelpfulsSlice';

export interface NewPromotionSiteReview {
  id?: number;
  promotion_site_id: number;
  stars: number;
  review_body: string;
  name: string;
  title: string;
  resourceType: string;
}

export interface EditPromotionSiteReview {
  id?: number;
  promotion_site_id: number;
  stars: number;
  review_body: string;
  title: string;
  name: string;
  resourceType: string;
}

export interface PromotionSiteReview {
  id: number;
  promotion_site_id: number;
  stars: number;
  review_body: string;
  created_at_time_ago: string;
  created_at: string;
  name: string;
  title: string;
  helpful_count: number;
  not_helpful_count: number;
  resourceType: string;
  current_partner_marked_helpful: boolean;
  current_partner_marked_not_helpful: boolean;
  owned_by_current_partner: boolean;
}

interface GenericApiParams {
  joins?: Array<string>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
}

const promotionSiteReviewsAdapter = createEntityAdapter<PromotionSiteReview>({
  sortComparer: (a: PromotionSiteReview, b: PromotionSiteReview) =>
    Date.parse(b.created_at) - Date.parse(a.created_at),
});

export const getAllPromotionSiteReviews = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotionSiteReviews/get', async (params: GenericApiParams, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.get('/api/v1/promotion_site_reviews', {
      params,
      headers,
    });
    thunkApi.dispatch(
      promotionSiteReviewsSlice.actions.promotionSiteReviewsReceivedReplace(
        response.data
      )
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const createPromotionSiteReview = createAsyncThunk<
  void, // Return type of the payload creator
  NewPromotionSiteReview, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotionSiteReviews/create', async (promotionSiteReview: NewPromotionSiteReview, thunkApi: any) => {
  const headers = { 'resource-type': promotionSiteReview.resourceType };

  try {
    const response = await axiosInstance.post('/api/v1/promotion_site_reviews', promotionSiteReview, {
      headers,
    });
    thunkApi.dispatch(promotionSiteReviewsSlice.actions.promotionSiteReviewReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, promotionSiteReview.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const manualGetPromotionSiteReview = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionSiteReview, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotionSiteReviews/manualCreate', async (promotionSiteReview: PromotionSiteReview, thunkApi: any) => {

  thunkApi.dispatch(promotionSiteReviewsSlice.actions.promotionSiteReviewReceived(promotionSiteReview));
});

export const updatePromotionSiteReview = createAsyncThunk<
  void,
  EditPromotionSiteReview,
  { rejectValue: Error }
>('promotionSiteReviews/update', async (promotionSiteReview: EditPromotionSiteReview, thunkApi: any) => {
  const headers = { 'resource-type': promotionSiteReview.resourceType };

  try {
    const response = await axiosInstance.patch(
      `/api/v1/promotion_site_reviews/${promotionSiteReview.id}/`,
      promotionSiteReview,
      { headers }
    );
    thunkApi.dispatch(promotionSiteReviewsSlice.actions.promotionSiteReviewReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, promotionSiteReview.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const promotionSiteReviewsSlice = createSlice({
  name: 'promotionSiteReviews',
  initialState: promotionSiteReviewsAdapter.getInitialState({}),
  reducers: {
    /**
     * A promotionSiteReview was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * promotionSiteReviews doesn't still show the promotionSiteReview whose status changed.
     */
    promotionSiteReviewChanged: (state, action: PayloadAction<number>) => {
      promotionSiteReviewsAdapter.removeOne(state, action);
    },
    promotionSiteReviewsReceivedReplace: (state, action: PayloadAction<PromotionSiteReview[]>) => {
      checkStringPayload(action.payload);
      promotionSiteReviewsAdapter.setAll(state, action.payload);
    },
    promotionSiteReviewsReceivedAdd: (state, action: PayloadAction<PromotionSiteReview[]>) => {
      checkStringPayload(action.payload);
      promotionSiteReviewsAdapter.upsertMany(state, action.payload);
    },
    promotionSiteReviewReceived: (state, action: PayloadAction<PromotionSiteReview>) => {
      promotionSiteReviewsAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPartnerHelpful.fulfilled, (state, action: PayloadAction<any>) => {
      promotionSiteReviewsAdapter.upsertOne(state, action.payload.promotion_site_review);
    });
    builder.addCase(createPartnerNotHelpful.fulfilled, (state, action: PayloadAction<any>) => {
      promotionSiteReviewsAdapter.upsertOne(state, action.payload.promotion_site_review);
    });
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = promotionSiteReviewsAdapter.getSelectors<RootState>(
  (state) => state.promotionSiteReviews
);
export const {
  selectAll: selectAllPromotionSiteReviews,
  selectById: selectPromotionSiteReviewById,
} = selectors;

export default promotionSiteReviewsSlice.reducer;
