/* eslint-disable import/no-cycle */
// Creates a slice for the partner.
// "This API is the standard approach for writing Redux logic"
// - https://redux-toolkit.js.org/api/createSlice

import Cookies from 'universal-cookie';
import { push } from 'connected-react-router';
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  clearCredentialsCookie,
  hasCredentials,
} from '../../utils/axios/middleware';
import { addSnack, handleErrors } from '../snacks/snacksSlice';
import {
  AuthHeaders,
  Credentials,
  Error as ErrorType,
} from '../current-user/currentUserSlice';

export interface CurrentPartner {
  id: number;
  email: string;
  affiliate_credit: number;
  affiliate_credits_used: number;
  affiliate_url: string;
  is_referral_credit_counted: boolean;
  is_subscribed_to_newsletter: boolean;
  num_credited_referrals: number;
  num_uncredited_referrals: number;
  referrer_id: number | null;
  subscribers_referred: number;
  signInError: string;
  isLoading: boolean;
  isSignedIn: boolean;
}

interface CurrentPartnerUpdate {
  email?: string;
  password?: string;
  password_confirmation?: string;
  current_password?: string;
}

interface ResetPasswordCredentials {
  password: string;
  password_confirmation: string;
  headers: any;
}

const cookies = new Cookies();

const currentPartnerAdapter = createEntityAdapter<CurrentPartner>({
  sortComparer: (a: CurrentPartner, b: CurrentPartner) => b.id - a.id,
});

// Getters - used in the front end to automatically reflect changes
// that occur in the store's reducers.
export const isLoggedInPartner = (state: RootState) =>
  !!state.currentPartner.isSignedIn;

export const isLoadingPartner = (state: RootState) =>
  !!state.currentPartner.isLoading;

// The function below is a "thunk" and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(findPartner(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const signInPartner = createAsyncThunk<
  any, // Return type of the payload creator
  Credentials, // First argument to the payload creator
  { rejectValue: string } // Types for ThunkAPI (the builders)
>('partner/signIn', async (credentials: Credentials, thunkApi: any) => {
  // Comment these in to sign in without entering credentials each time.
  // Speeds development up significantly.
  // credentials.email = 'coreysan13@gmail.com';
  // credentials.password = 'password';
  if (credentials.email.trim() === '') {
    return thunkApi.rejectWithValue('Please enter an email address');
  }
  if (credentials.password.trim() === '') {
    return thunkApi.rejectWithValue('Please enter a password');
  }
  const authHeaders = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.post(
      // TODO CS - put this in an env var
      // Just here for simple testing
      // Getting cors errors from the server and can't seem to
      // enable cors
      '/api/v1/partner-auth/sign_in',
      { ...credentials },
      { headers: authHeaders }
    );

    thunkApi.dispatch(
      currentPartnerSlice.actions.currentPartnerChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    // If it's a typical 401, don't show the error in the
    // snackbar, show it in the sign-up form.
    if (error?.response?.status !== 401) {
      handleErrors(error, thunkApi.dispatch, 'partner');
    }
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const signUpPartner = createAsyncThunk<
  any, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: ErrorType } // Types for ThunkAPI (the builders)
>('partner/signUp', async (signUpParams: any, thunkApi: any) => {
  const authHeaders = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.post(
      '/api/v1/partner-auth',
      signUpParams,
      {
        headers: authHeaders,
      }
    );

    thunkApi.dispatch(
      currentPartnerSlice.actions.currentPartnerChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const forgotPasswordPartner = createAsyncThunk<
  any, // Return type of the payload creator
  string, // First argument to the payload creator
  { rejectValue: ErrorType } // Types for ThunkAPI (the builders)
>('partner/forgotPassword', async (email: string, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.post(
      '/api/v1/partner-auth/password',
      {
        email,
        redirect_url: `${process.env.REACT_APP_BOOKRAID_API_URL}/partners/password/reset`,
      },
      { headers }
    );

    thunkApi.dispatch(
      addSnack({ message: response.data.message, variant: 'success' })
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const resetPasswordPartner = createAsyncThunk<
  any, // Return type of the payload creator
  ResetPasswordCredentials, // First argument to the payload creator
  { rejectValue: ErrorType } // Types for ThunkAPI (the builders)
>(
  'partner/resetPassword',
  async (passwordUpdate: ResetPasswordCredentials, thunkApi: any) => {
    try {
      const response = await axiosInstance.patch(
        '/api/v1/partner-auth/password',
        {
          password: passwordUpdate.password,
          password_confirmation: passwordUpdate.password_confirmation,
        },
        { headers: passwordUpdate.headers }
      );
      thunkApi.dispatch(
        currentPartnerSlice.actions.currentPartnerChanged(response.data.data)
      );
      thunkApi.dispatch(
        addSnack({ message: response.data.message, variant: 'success' })
      );
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch, 'partner');
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/**
 * For non-authenticated pages, we still attempt to validate
 * the partner using this action.
 *
 * This is so that we can show logged-in information on the
 * (mostly) static pages.
 *
 * This differs from the `validateFromCookiePartner` function
 * in that we don't sign them out or show any error snack.
 */
export const attemptPartnerValidation = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument to the payload creator
  { rejectValue: ErrorType } // Types for ThunkAPI (the builders)
>('partner/validateFromCookie', async (nothing, thunkApi: any) => {
  const headers: AuthHeaders = cookies.get('partner');

  thunkApi.dispatch(currentPartnerSlice.actions.setLoading(true));

  if (!headers) {
    return thunkApi.rejectWithValue();
  }

  const authHeaders = { 'resource-type': 'partner' };
  // validating token
  try {
    const response = await axiosInstance.get(
      '/api/v1/partner-auth/validate_token',
      {
        headers: authHeaders,
      }
    );

    thunkApi.dispatch(
      currentPartnerSlice.actions.currentPartnerChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    clearCredentialsCookie('partner');
    thunkApi.dispatch(currentPartnerSlice.actions.signOut());
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const validateFromCookiePartner = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument to the payload creator
  { rejectValue: ErrorType } // Types for ThunkAPI (the builders)
>('partner/validateFromCookie', async (nothing, thunkApi: any) => {
  const headers: AuthHeaders = cookies.get('partner');

  thunkApi.dispatch(currentPartnerSlice.actions.setLoading(true));

  if (!headers) {
    thunkApi.dispatch(
      addSnack({
        message:
          "It looks like you're not signed in. Please sign in before continuing.",
        variant: 'error',
      })
    );
    thunkApi.dispatch(push('/partners/sign-in'));
    return thunkApi.rejectWithValue();
  }

  const authHeaders = { 'resource-type': 'partner' };
  // validating token
  try {
    const response = await axiosInstance.get(
      '/api/v1/partner-auth/validate_token',
      {
        headers: authHeaders,
      }
    );

    thunkApi.dispatch(
      currentPartnerSlice.actions.currentPartnerChanged(response.data.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    clearCredentialsCookie('partner');
    thunkApi.dispatch(currentPartnerSlice.actions.signOut());
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/**
 * Log the partner out by:
 *   - submitting a delete request to the BR session auth
 *   - removing cookies
 *   - Redirecting back to the sign-in page
 *
 * If they don't have any credentials anyway, skip the api request
 * to log them out.
 */
export const logoutPartner = createAsyncThunk(
  'partner/logout',
  async (params, thunkApi: any) => {
    const headers = { 'resource-type': 'partner' };

    if (!hasCredentials('partner')) {
      thunkApi.dispatch(push('/'));
      return null;
    }

    try {
      const response = await axiosInstance.delete(
        '/api/v1/partner-auth/sign_out',
        {
          headers,
        }
      );
      clearCredentialsCookie('partner');
      thunkApi.dispatch(currentPartnerSlice.actions.signOut());
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      // eslint-disable-next-line no-console
      console.error('Failed to log out');
      // eslint-disable-next-line no-console
      console.error(error);
      handleErrors(error, thunkApi.dispatch, 'partner');
      clearCredentialsCookie('partner');
      thunkApi.dispatch(currentPartnerSlice.actions.signOut());
      // Send back to home, even if the logout failed. Usually due to session
      // updating after local seeding occurs
      thunkApi.dispatch(push('/'));
    }
  }
);

export const updatePartner = createAsyncThunk<
  void, // Return type of the payload creator
  CurrentPartnerUpdate, // First argument to the payload creator
  { rejectValue: ErrorType } // Types for ThunkAPI (the builders)
>(
  'creditCards/update',
  async (currentPartner: CurrentPartnerUpdate, thunkApi: any) => {
    const headers = { 'resource-type': 'partner' };

    try {
      const response = await axiosInstance.patch(
        '/api/v1/partner-auth',
        currentPartner,
        {
          headers,
        }
      );
      thunkApi.dispatch(
        currentPartnerSlice.actions.currentPartnerChanged(response.data.data)
      );
      thunkApi.dispatch(
        addSnack({
          message: 'Your account details were successfully updated',
          variant: 'success',
        })
      );
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch, 'partner');
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/**
 * PARTNER SLICE
 */
export const currentPartnerSlice = createSlice({
  name: 'currentPartner',
  initialState: currentPartnerAdapter.getInitialState({
    email: '',
    signInError: '',
    isLoading: true,
    isSignedIn: false,
  }),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    // see the counterSlice file for examples of this
    // not sure what these are used for yet, or if we're just
    // using the extraReducers below
    signOut: (state) => {
      state.isSignedIn = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    currentPartnerChanged: (state, action: PayloadAction<CurrentPartner>) => {
      currentPartnerAdapter.upsertOne(state, action.payload);
      state.isSignedIn = true;
      state.isLoading = false;
    },
  },
});

const selectors = currentPartnerAdapter.getSelectors<RootState>(
  (state) => state.currentPartner
);
export const { selectAll: selectCurrentPartner } = selectors;

export default currentPartnerSlice.reducer;
