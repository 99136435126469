import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface RouterLinkProps extends LinkProps {
  to: string;
  underline?: 'none' | 'hover' | 'always';
}

/**
 * Wrap the Mui link with a router link
 * to take advantage of both the Mui styling
 * and the router's routing.
 *
 * Given the interface above, it requires that a
 * "to" directive is given.
 *
 * @param props the props for the component
 * @returns
 */
const MuiRouterLink = function (props: RouterLinkProps) {
  const { children, sx, ...restOfProps } = props;

  return (
    <Link
      sx={{ ...sx }}
      component={RouterLink}
      {...restOfProps}
    >
      {children}
    </Link>
  );
};

MuiRouterLink.defaultProps = {
  underline: 'none',
};

export default MuiRouterLink;
