/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axiosInstance, { axios } from '../../utils/axios';
import { Category } from '../categories/categoriesSlice';
import { Promotion } from '../promotions/promotionsSlice';

import { checkStringPayload, handleErrors } from '../snacks/snacksSlice';
import { Author } from '../authors/authorsSlice';

export interface Partner {
  id: number | null;
  name: string;
  email: string;
}

export interface Book {
  // This index signature allows us to reference a key from a book object dynamically,
  // like with: book['amazon'+'_url']
  [index: string]: any;
  id?: number | null;
  title: string;
  category_id: number | null;
  asin: string;
  description: string;
  amazon_image_url?: string;
  amazon_url: string;
  ibooks_url: string;
  barnes_and_noble_url: string;
  google_play_url: string;
  kobo_url: string;
  category?: Category | null;
  partner?: Partner | null;
  author?: Author | null;
  promotions?: Array<Promotion> | null;
  amazon_pages?: number;
  amazon_language?: string;
  amazon_categories?: string;
  user_book?: boolean;
  created_at?: Date;
  updated_at?: Date;
  newAcct?: boolean;
}

// Core properties not typically exported.
// The types should typically be maintained solely in the slice file?
// Not sure about the reponse type.
interface BookCore {
  title: string;
  author_id: number | null;
  category_id: number | null;
  asin: string;
  description: string;
  amazon_url: string;
  ibooks_url: string;
  barnes_and_noble_url: string;
  google_play_url: string;
  kobo_url: string;
}

// This type would be required when accepting a submission from a form
// where the entity does not yet have a value.
// export interface BookNewProperties extends BookCore {
//   id?: number;
// }

// All the core properties, plus the ID.
export interface BookCreated extends BookCore {
  id: number;
}

// This type essentially represents the full list of properties that exist
// on _every_ response where this object exists.
// The consumer of the slice action may request for additional joins, in which case
// this type would be extended in the tsx file that includes the extra joins.
//
// It should also be noted that joins can be included from either:
// 1. The dispatch coming from the tsx
// 2. The actions and axios calls below.
//
// It remains to be seen whether all those types should be defined here, or if
// some of them can be defined in the tsx file.
export interface BookResponse extends BookCreated {
  category: Category;
  partner?: Partner;
  author?: Author;
  partner_id?: number;
  amazon_image_url: string;
  promotions: Array<Promotion>;
  amazon_pages: number;
  amazon_language: string;
  user_book: boolean;
  amazon_categories?: string;
  last_promotion_date?: string;
  next_possible_promotion_date?: string;
  has_active_promotion: boolean;
  created_at: Date;
  updated_at: Date;
  immediate_repromote: boolean;
  latest_successful_promotion?: Promotion;
}

const booksAdapter = createEntityAdapter<BookResponse>({});

interface GenericApiParams {
  id?: number;
  resourceType?: string;
  public?: boolean;
  query?: string;
  joins?: Array<any>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
}

export const getBook = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('books/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(`/api/v1/books/${params.id}`, {
      headers: { 'resource-type': params.resourceType },
      params: {
        public: params.public,
        joins: params.joins,
      },
    });
    thunkApi.dispatch(booksSlice.actions.bookReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

interface UpdateParams {
  resourceType: string;
  book: Book;
}
export const getAllBooks = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('books/getAll', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get('/api/v1/books', {
      params,
      headers: { 'resource-type': params.resourceType },
    });
    thunkApi.dispatch(booksSlice.actions.booksReceived(response.data.books));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updateBook = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('book/update', async (params: UpdateParams, thunkApi: any) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/books/${params.book.id}/`,
      params.book,
      {
        headers: { 'resource-type': params.resourceType },
        params: {
          joins: ['category', 'partner'],
        },
      }
    );
    thunkApi.dispatch(booksSlice.actions.bookReceived(response.data));
    return response.data;
  } catch (error) {
    // If it's no an axios error or if we're a partner, just
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const fetchAmznDetails = createAsyncThunk<
  void, // Return type of the payload creator
  string, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('book/asinFetch', async (asin: string, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.get(`/books/${asin}/amazon_api_data`, {
      headers,
    });
    // this information is only needed in the component that requested it,
    // so don't bother storing it in store
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const refreshCover = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('book/refresh-cover', async (params: GenericApiParams, thunkApi: any) => {
  const headers = { 'resource-type': params.resourceType };
  try {
    const response = await axiosInstance.post(
      `/api/v1/books/${params.id}/refresh-cover`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const createBook = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('book/create', async (book: Book, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.post(`/api/v1/books`, book, {
      headers,
    });
    thunkApi.dispatch(booksSlice.actions.bookReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const booksSlice = createSlice({
  name: 'book',
  initialState: booksAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    bookReceived: (state, action: PayloadAction<BookResponse>) => {
      checkStringPayload(action.payload);
      booksAdapter.upsertOne(state, action.payload);
    },
    booksReceived: (state, action: PayloadAction<BookResponse[]>) => {
      checkStringPayload(action.payload);
      booksAdapter.setAll(state, action.payload);
    },
    removeAllBooks: (state) => {
      booksAdapter.removeAll(state);
    }
  },
});

const selectors = booksAdapter.getSelectors<RootState>((state) => state.books);
export const { selectById: selectBook, selectAll: selectAllBooks } = selectors;

export default booksSlice.reducer;
