import { darken, lighten, Theme } from '@mui/material/styles';
import constants from './constants';

/**
 * Define any additional colors on application components.
 *
 * @param theme
 * @returns
 */
export default function colors(theme: Theme) {
  const navAndFooter = {
    background: `linear-gradient(45deg, ${theme.palette.primary.light} -20%, ${theme.palette.primary.main} 40%)`,
    boxShadow: '0px 0px 20px rgb(0 0 0 / 10%)',
  };

  const categories: { [category: string]: { backgroundColor: string } } = {
    mystery: { backgroundColor: lighten('#dbf1ff', 0.5) }, // MTS -- Mystery
    thriller: { backgroundColor: lighten('#dbecff', 0.5) }, // MTS -- Thriller
    actionAndAdventure: { backgroundColor: lighten('#dbe0ff', 0.5) }, // MTS -- Action and Adventure
    supernaturalSuspense: { backgroundColor: lighten('#d8d5e8', 0.5) }, // MTS -- Supernatural Suspense
    historicalFiction: { backgroundColor: lighten('#f3ece4', 0.5) }, // GF -- Historical Fiction
    literaryFiction: { backgroundColor: lighten('#f9f3cd', 0.5) }, // GF -- Literary Fiction
    womensFiction: { backgroundColor: lighten('#e7f9cd', 0.5) }, // GF -- Womens Fiction
    christianFiction: { backgroundColor: lighten('#f3f9cd', 0.5) }, // GF -- Christian Fiction
    adviceAndHowTo: { backgroundColor: lighten('#e4dcdc', 0.5) }, // NF -- Advice and How-To
    religious: { backgroundColor: lighten('#e8e3d8', 0.5) }, // NF -- Religious
    biosAndMemoirs: { backgroundColor: lighten('#ebe8cb', 0.5) }, // NF -- Bios and Memoirs
    history: { backgroundColor: lighten('#e4e6d0', 0.5) }, // NF -- History
    generalNonFiction: { backgroundColor: lighten('#e1edc4', 0.5) }, // NF -- General Non-Fiction
    cooking: { backgroundColor: lighten('#d1eec3', 0.5) }, // NF -- Cooking
    paranormalRomance: { backgroundColor: lighten('#ece3f5', 0.5) }, // Rom -- Paranormal Romance
    contemporaryRomance: { backgroundColor: lighten('#efdef5', 0.5) }, // Rom -- Contemporary Romance
    romanticSuspense: { backgroundColor: lighten('#dadaee', 0.5) }, // Rom -- Romantic Suspense
    romanticComedy: { backgroundColor: lighten('#f7eef7', 0.5) }, // Rom -- Romantic Comedy
    historicalRomance: { backgroundColor: lighten('#e4dde4', 0.5) }, // Rom -- Historical Romance
    eroticRomance: { backgroundColor: lighten('#eed2ee', 0.5) }, // Rom -- Erotic Romance
    erotica: { backgroundColor: lighten('#f9c6f9', 0.5) }, // Rom -- Erotica
    childrensAndMiddleGrade: {
      backgroundColor: lighten('#e1fcf6', 0.5),
    }, // You -- Childrens and Middle Grade
    teenAndYoungAdult: { backgroundColor: lighten('#ddeeee', 0.5) }, // You -- Teen and Young Adult
    horror: { backgroundColor: lighten('#deacac', 0.5) }, // ScFH -- Horror
    fantasy: { backgroundColor: lighten('#f9d5c8', 0.5) }, // ScFH -- Fantasy
    scienceFiction: { backgroundColor: lighten('#f9e1c8', 0.5) }, // ScFH -- Science Fiction
    ///
    mysteryThrillerAndSuspense: {
      backgroundColor: lighten('#d1f0d3', 0.5),
    }, // Super Cat -- Mystery, Thriller and Suspense
    romance: { backgroundColor: lighten('#f6e9e9', 0.5) }, // Super Cat -- Romance
    scienceFictionFantasyHorror: {
      backgroundColor: lighten('#d4e4d3', 0.5),
    }, // Science Fiction, Fantasy, Horror
    youngReaders: { backgroundColor: lighten('#f0fdb4', 0.5) }, // Super Cat -- Young Readers
    generalFiction: { backgroundColor: lighten('#e0edf1', 0.5) }, // Super Cat -- General Fiction
    nonFiction: { backgroundColor: lighten('#f7f0e4', 0.5) }, // Super Cat -- Non-Fiction
  };

  const cancelledColor = '#EFD9D9';
  const declinedColor = '#eeeeee';
  const awaitingPaymentColor = '#F1A7A7';
  const unableToSendColor = '#F9DDFB';
  const awaitingReviewColor = '#c2ffcc';
  const feedbackSubmittedColor = '#c2ffcc';
  const paymentFailedColor = '#F35757';
  const feedbackColor = '#FFA700';
  const awaitingPromotionColor = '#01c951';
  const successfullySentColor = '#00b0ff';
  const statuses: {
    [status: string]: {
      borderColor: string;
      backgroundColor: string;
    };
  } = {
    paymentFailed: {
      borderColor: paymentFailedColor,
      backgroundColor: lighten(paymentFailedColor, 0.8),
    },
    cancelled: {
      borderColor: cancelledColor,
      backgroundColor: lighten(cancelledColor, 0.5),
    }, // subdued red/pink
    declined: {
      borderColor: declinedColor,
      backgroundColor: lighten(declinedColor, 0.5),
    }, // grey
    awaitingPayment: {
      borderColor: awaitingPaymentColor,
      backgroundColor: lighten(awaitingPaymentColor, 0.5),
    }, // red-salmon
    successfullySent: {
      borderColor: successfullySentColor,
      backgroundColor: lighten(successfullySentColor, 0.5),
    }, // green/teal
    unableToSend: {
      borderColor: unableToSendColor,
      backgroundColor: lighten(unableToSendColor, 0.5),
    }, // light purple
    awaitingReview: {
      borderColor: awaitingReviewColor,
      backgroundColor: lighten(awaitingReviewColor, 0.5),
    }, // teal/light green
    awaitingPartnerFeedback: {
      borderColor: feedbackColor,
      backgroundColor: lighten('#ff9d80', 0.5),
    }, // light red
    feedbackSubmitted: {
      borderColor: feedbackSubmittedColor,
      backgroundColor: lighten(feedbackSubmittedColor, 0.5),
    },
    awaitingPromotionDate: {
      borderColor: awaitingPromotionColor,
      backgroundColor: lighten(awaitingPromotionColor, 0.5),
    }, // lighter green
  };

  const stores: {
    [store: string]: { color: string; backgroundColor?: string };
  } = {
    amazon: { color: '#ff9900' }, // orange
    ibooks: { color: '#b16517' }, // brown/wood
    barnesAndNoble: { color: '#428050' }, // green
    googlePlay: { color: '#4dcdc7' }, // teal
    kobo: { color: '#825aae' }, // purple
  };

  // Create light and dark versions of each color,
  // plus a -bg for each store that's very light.
  // Accessed at, for example:
  // - amazon-dark
  // - amazon-light
  // - amazon-bg
  Object.keys(stores).forEach((store) => {
    stores[`${store}-dark`] = { color: darken(stores[store].color, 0.5) };
    stores[`${store}-light`] = { color: lighten(stores[store].color, 0.5) };
    stores[`${store}-bg`] = {
      color: darken(stores[store].color, 0.5),
      backgroundColor: lighten(stores[store].color, 0.75),
    };
  });

  return {
    nav: {
      ...navAndFooter,
      border: `${constants.navbar.border.height}px solid ${lighten(
        theme.palette.primary.light,
        0.5
      )}`,
    },
    free: {
      color: '#46a35a',
    },
    payPerClickGraphic: {
      money: '#ff6670',
      mouse: '#429bd9',
    },
    approvalStatus: {
      approved: { backgroundColor: '#dff0d8' },
      rejected: { backgroundColor: '#f2dede' },
      awaitingFeedback: { backgroundColor: '#fcf8e3' },
    },
    subdued: { color: '#777' },
    moreSubdued: { color: '#999' },
    waySubdued: { color: '#bbb' },
    categories,
    statuses,
    stores,
    footer: {
      ...navAndFooter,
      border: `${constants.navbar.border.height}px solid ${lighten(
        theme.palette.primary.light,
        0.3
      )}`,
      boxShadow: '0px 0px 20px rgb(0 0 0 / 20%)',
    },
  };
}
