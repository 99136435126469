import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { Route as RouteType } from './RouteTypes';
import AppErrorBoundary from '../../../AppErrorBoundary';
import SuspenseLoader from '../SuspenseLoader';

// The public routes are ALL loaded from separate bundles.
// Every one is lazy loaded

const publicRoutes: RouteType[] = [
  {
    path: '/partners/sign-in',
    component: React.lazy(() => import('../../../components/partner/SignIn')),
  },
  {
    path: '/partners/sign-up',
    component: React.lazy(() => import('../../../components/partner/SignUp')),
  },
  {
    path: '/partners/password/forgot',
    component: React.lazy(
      () => import('../../../components/partner/ForgotPassword')
    ),
  },
  {
    path: '/partners/password/reset',
    component: React.lazy(
      () => import('../../../components/partner/ResetPassword')
    ),
  },
  {
    path: '/partners/unsubscribe-reminder-email',
    component: React.lazy(
      () =>
        import(
          '../../../components/partner/unsubscribe-reminder-emails/UnsubscribeReminderEmails'
        )
    ),
  },
  {
    path: '/partners/unsubscribe-recapture-email',
    component: React.lazy(
      () =>
        import(
          '../../../components/partner/unsubscribe-recapture-emails/UnsubscribeRecaptureEmails'
        )
    ),
  },
];

const PublicPartnerRoutes = function () {
  return (
    <>
      {publicRoutes.map((route: RouteType) => (
        <Route path={route.path} exact key={route.path}>
          <Suspense fallback={<SuspenseLoader />}>
            <AppErrorBoundary>
              {React.createElement(route.component, {})}
            </AppErrorBoundary>
          </Suspense>
        </Route>
      ))}
    </>
  );
};

export default PublicPartnerRoutes;
