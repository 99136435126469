/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import { checkStringPayload, handleErrors } from '../snacks/snacksSlice';

export interface CategoryNewsletterPromotionCount {
  category_id: number;
  newsletter_promotion_counts: NewsletterPromotionCount[];
}

export interface NewsletterPromotionCount {
  id: number;
  newsletter_date: string;
  promotions_in_category: number;
  promotions_in_total: number;
}

const newsletterPromoCountAdapter =
  createEntityAdapter<CategoryNewsletterPromotionCount>({
    selectId: (categoryNewsletterPromoCount) =>
      categoryNewsletterPromoCount.category_id,
  });

export const getNewsletterPromoCounts = createAsyncThunk<
  void,
  number,
  { rejectValue: Error }
>(
  'newsletterPromotionCounts/get',
  async (categoryId: number, thunkApi: any) => {
    try {
      const response = await axiosInstance.get(
        `/api/v1/newsletter_promotion_counts?category_id=${categoryId}`
      );
      thunkApi.dispatch(
        newsletterPromoCountsSlice.actions.newsletterPromoCountsReceived({
          category_id: categoryId,
          newsletter_promotion_counts: response.data,
        })
      );
      return response.data;
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }
      handleErrors(error, thunkApi.dispatch);
      return thunkApi.rejectWithValue(error?.response?.data);
    }
  }
);

/** ******* MAIN SLICE ******* */
export const newsletterPromoCountsSlice = createSlice({
  name: 'newsletterPromoCounts',
  initialState: newsletterPromoCountAdapter.getInitialState({}),
  reducers: {
    newsletterPromoCountsReceived: (
      state,
      action: PayloadAction<CategoryNewsletterPromotionCount>
    ) => {
      checkStringPayload(action.payload);
      newsletterPromoCountAdapter.upsertOne(state, action.payload);
    },
  },
});

const selectors = newsletterPromoCountAdapter.getSelectors<RootState>(
  (state) => state.newsletterPromoCounts
);
export const {
  selectAll: selectAllNewsletterPromoCounts,
  selectById: selectNewsletterPromoCountById,
} = selectors;

export default newsletterPromoCountsSlice.reducer;
