// This navbar copied from this codesandbox project:
// https://codesandbox.io/s/how-to-build-a-material-ui-navbar-cu3sf?file=/src/components/Header.jsx:793-824
import React, { CSSProperties } from 'react';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  AccountCircle,
  Add,
  AdminPanelSettings,
  Dashboard,
  Logout,
  Memory,
  Menu as MenuIcon,
} from '@mui/icons-material';
import { push } from 'connected-react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  isLoggedInPartner,
  logoutPartner,
} from '../../store/current-partner/currentPartnerSlice';
import diamondOnly1x from '../../images/logos/v3.1/diamond-only.png';
import bookOnly1x from '../../images/logos/v3.1/book-only.png';
import diamondOnly2x from '../../images/logos/v3.1/diamond-only@2x.png';
import bookOnly2x from '../../images/logos/v3.1/book-only@2x.png';
import theme, { colors } from '../../theme/theme';
import constants from '../../theme/constants';
import HamburgerNav from './HamburgerNav';
import MuiRouterLink from '../mui-wrappers/MuiRouterLink';
import {
  isLoggedInUser,
  logoutUser,
} from '../../store/current-user/currentUserSlice';
import partnerMenuItems from '../../theme/partnerMenuItems';

const BookImage = styled('img')(() => ({
  position: 'absolute',
  top: 5,
  left: 3,
}));

const DiamondImage = styled('img')(() => ({
  position: 'absolute',
  top: 3,
  left: 3,
}));

const classes = {
  appBar: {
    background: colors.nav.background,
    boxShadow: colors.nav.boxShadow,
    borderBottom: colors.nav.border,
    height: {
      xs: `${constants.navbar.mobileHeight}px`,
      md: `${constants.navbar.height}px`,
    },
    zIndex: theme.zIndex.drawer + 1,
    padding: 0,
  },
  toolbar: {
    padding: 0,
    margin: 0,
    minHeight: '60px !important',
  },
  navbarDisplayFlex: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  logoAndTitleWrapper: {
    flex: '0 0 280px',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 230px',
    },
  },
  logoWrapper: {
    flex: '0 0 53px',
    width: 45,
    height: 51,
    position: 'relative',
    ml: 1,
    mt: 1,
    [theme.breakpoints.down('md')]: {
      pl: '5px',
      ml: 0.25,
    },
  },
  title: {
    flex: '0 0 230px',
    lineHeight: 1,
    color: 'white',
    fontSize: '50px',
    paddingLeft: '3px',
    pt: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: '46px',
      flex: '0 0 230px',
    },
    '& a': {
      color: 'white',
      textDecoration: 'none',
    },
  },
};

const Logo = (
  <>
    <DiamondImage
      src={diamondOnly1x}
      srcSet={`${diamondOnly1x} 1x,${diamondOnly2x} 2x`}
      alt="logo"
      width={44}
      height={51}
    />
    <BookImage
      src={bookOnly1x}
      srcSet={`${bookOnly1x} 1x,${bookOnly2x} 2x`}
      alt="logo"
      width={44}
      height={51}
    />
  </>
);

const Navbar = function () {
  const partnerLoggedIn = useAppSelector(isLoggedInPartner);
  const userLoggedIn = useAppSelector(isLoggedInUser);
  const dispatch = useAppDispatch();

  const [anchorElPartner, setAnchorElPartner] =
    React.useState<null | HTMLElement>(null);
  const [anchorElPartnerAccount, setAnchorElPartnerAccount] =
    React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElAi, setAnchorElAi] = React.useState<null | HTMLElement>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [isLoggingPartnerOut, setIsLoggingPartnerOut] = React.useState(false);
  const [isLoggingUserOut, setIsLoggingUserOut] = React.useState(false);

  const loggingPartnerOut = () => {
    setIsLoggingPartnerOut(true);
    dispatch(logoutPartner())
      .unwrap()
      .then(() => {
        dispatch(push('/partners/sign-in'));
      })
      .finally(() => {
        setIsLoggingPartnerOut(false);
        handleClosePartnerMenu();
      });
  };

  const loggingUserOut = () => {
    setIsLoggingUserOut(true);
    dispatch(logoutUser())
      .unwrap()
      .then(() => {
        dispatch(push('/partners/sign-in'));
      })
      .finally(() => {
        setIsLoggingUserOut(false);
        handleCloseUserMenu();
      });
  };

  const handleOpenPartnerMenu = (event: any) => {
    setAnchorElPartner(event.currentTarget);
  };

  const handleClosePartnerMenu = () => {
    setAnchorElPartner(null);
  };

  const handleOpenPartnerAccountMenu = (event: any) => {
    setAnchorElPartnerAccount(event.currentTarget);
  };

  const handleClosePartnerAccountMenu = () => {
    setAnchorElPartnerAccount(null);
  };

  const handleOpenUserMenu = (event: any) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAiMenu = (event: any) => {
    setAnchorElAi(event.currentTarget);
  };

  const handleCloseAiMenu = () => {
    setAnchorElAi(null);
  };

  const aiMenuContent = () => (
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={anchorElAi}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElAi)}
      onClose={handleCloseAiMenu}
    >
      <MuiRouterLink to="/ai/book-description-generator">
        <MenuItem onClick={handleCloseAiMenu}>
          AI Book Description Generator
        </MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/ai/pen-name-generator">
        <MenuItem onClick={handleCloseAiMenu}>AI Pen Name Generator</MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/ai/book-character-generator">
        <MenuItem onClick={handleCloseAiMenu}>
          AI Book Character Generator
        </MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/ai/book-title-generator">
        <MenuItem onClick={handleCloseAiMenu}>AI Book Title Generator</MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/ai/book-plot-generator">
        <MenuItem onClick={handleCloseAiMenu}>AI Book Plot Generator</MenuItem>
      </MuiRouterLink>
    </Menu>
  );

  const partnerMenuContent = () => (
    <Menu
      sx={{ mt: '45px' }}
      anchorEl={anchorElPartner}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElPartner)}
      onClose={handleClosePartnerMenu}
    >
      <MuiRouterLink to="/partners/sign-in">
        <MenuItem onClick={handleClosePartnerMenu}>
          <Typography>Sign In</Typography>
        </MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/partners/sign-up">
        <MenuItem onClick={handleClosePartnerMenu}>
          <Typography>Sign Up</Typography>
        </MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/partners">
        <MenuItem onClick={handleClosePartnerMenu}>
          <Typography>Learn More</Typography>
        </MenuItem>
      </MuiRouterLink>
      <MuiRouterLink to="/author-resources">
        <MenuItem onClick={handleClosePartnerMenu}>
          <Typography>Author Resources</Typography>
        </MenuItem>
      </MuiRouterLink>
    </Menu>
  );

  return (
    <AppBar position="fixed" sx={classes.appBar}>
      <Toolbar sx={classes.toolbar} disableGutters>
        <Container
          maxWidth={false}
          disableGutters
          sx={classes.navbarDisplayFlex as CSSProperties}
        >
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Box sx={classes.logoAndTitleWrapper}>
              <Box sx={classes.logoWrapper as CSSProperties}>
                <MuiRouterLink to="/">{Logo}</MuiRouterLink>
              </Box>
              <Box sx={classes.title}>
                <MuiRouterLink to="/">BookRaid</MuiRouterLink>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mt: 1 }}>
              <Button
                to="/ebook-deals"
                component={RouterLink}
                color="secondary"
                sx={{
                  display: { xs: 'none', md: 'block' },
                  mr: 1,
                  fontSize: '1rem',
                  color: '#fff',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                }}
              >
                All eBooks
              </Button>
              <Button
                to="/ebook-deals/free-ebooks"
                component={RouterLink}
                color="secondary"
                sx={{
                  display: { xs: 'none', md: 'block' },
                  mr: 1,
                  fontSize: '1rem',
                  color: '#fff',
                  textTransform: 'none',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                }}
              >
                Free eBooks
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mr: 1, mt: 1 }}>
            <Button
              color="secondary"
              sx={{
                display: { xs: 'none', md: 'block' },
                mr: 1,
                fontSize: '1rem',
                color: '#fff',
                textTransform: 'none',
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
              }}
              onClick={(e) => handleOpenAiMenu(e)}
              onKeyDown={(e) => handleOpenAiMenu(e)}
            >
              AI Book Tools
            </Button>

            <Button
              to="/author-resources"
              component={RouterLink}
              color="secondary"
              sx={{
                display: { xs: 'none', md: 'block' },
                textAlign: 'center',
                mr: 1,
                fontSize: '1rem',
                color: '#fff',
                textTransform: 'none',
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
              }}
            >
              Author Resources
            </Button>

            <IconButton
              sx={{
                display: { xs: 'inline-flex', md: 'none' },
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                mr: 1,
              }}
              onClick={(e) => handleOpenAiMenu(e)}
              onKeyDown={(e) => handleOpenAiMenu(e)}
            >
              <Memory fontSize="large" sx={{ color: '#fff' }} />
            </IconButton>
            {aiMenuContent()}

            {partnerLoggedIn ? (
              <>
                {/* MOBILE */}
                <IconButton
                  sx={{
                    display: { xs: 'inline-flex', md: 'none' },
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                    mr: 1,
                  }}
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  onKeyDown={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <MenuIcon fontSize="large" sx={{ color: '#fff' }} />
                </IconButton>

                {/* DESKTOP */}
                <Tooltip title="Open user menu">
                  <IconButton
                    sx={{
                      display: { xs: 'none', md: 'inline-flex' },
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      },
                      mr: 1,
                    }}
                    onClick={(e) => handleOpenPartnerAccountMenu(e)}
                    onKeyDown={(e) => handleOpenPartnerAccountMenu(e)}
                  >
                    <AccountCircle fontSize="large" sx={{ color: '#fff' }} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElPartnerAccount}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElPartnerAccount)}
                  onClose={handleClosePartnerAccountMenu}
                >
                  {partnerMenuItems
                    .filter((mi) => mi.displayInDesktopMenu)
                    .map((setting) => (
                      <MuiRouterLink key={setting.text} to={setting.url}>
                        <MenuItem onClick={handleClosePartnerAccountMenu}>
                          <ListItemIcon>
                            {React.createElement(setting.icon, {
                              sx: { color: 'primary.main' },
                            })}
                          </ListItemIcon>
                          <ListItemText>{setting.text}</ListItemText>
                        </MenuItem>
                      </MuiRouterLink>
                    ))}
                  <Divider />
                  <MenuItem onClick={() => loggingPartnerOut()}>
                    <ListItemIcon>
                      <Logout sx={{ color: 'primary.main' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: 'primary.main' }}>
                      Log out
                    </ListItemText>
                    {isLoggingPartnerOut && <CircularProgress size="1em" />}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  color="secondary"
                  sx={{
                    fontSize: '1rem',
                    color: '#fff',
                    textTransform: 'none',
                    display: { xs: 'none', md: 'block' },
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                  }}
                  onClick={(e) => handleOpenPartnerMenu(e)}
                  onKeyDown={(e) => handleOpenPartnerMenu(e)}
                >
                  Author or Publisher?
                </Button>

                <IconButton
                  sx={{
                    display: { xs: 'inline-flex', md: 'none' },
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                    mr: 1,
                  }}
                  onClick={(e) => handleOpenPartnerMenu(e)}
                  onKeyDown={(e) => handleOpenPartnerMenu(e)}
                >
                  <AccountCircle fontSize="large" sx={{ color: '#fff' }} />
                </IconButton>
                {partnerMenuContent()}
              </>
            )}

            {mobileMenuOpen && (
              <HamburgerNav setMobileMenuOpen={setMobileMenuOpen} />
            )}

            {userLoggedIn && (
              <>
                <IconButton
                  sx={{
                    display: { xs: 'none', md: 'inline-flex' },
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
                    mr: 1,
                  }}
                  onClick={(e) => handleOpenUserMenu(e)}
                >
                  <AdminPanelSettings fontSize="large" sx={{ color: '#fff' }} />
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MuiRouterLink to="/users/promotions">
                    <MenuItem onClick={handleCloseUserMenu}>
                      <ListItemIcon>
                        <Dashboard sx={{ color: 'primary.main' }} />
                      </ListItemIcon>
                      <ListItemText>Dashboard</ListItemText>
                    </MenuItem>
                  </MuiRouterLink>

                  <MuiRouterLink to="/users/blog-posts/new">
                    <MenuItem onClick={handleCloseUserMenu}>
                      <ListItemIcon>
                        <Add sx={{ color: 'primary.main' }} />
                      </ListItemIcon>
                      <ListItemText>New Blog Post</ListItemText>
                    </MenuItem>
                  </MuiRouterLink>

                  <Divider />

                  <MenuItem onClick={() => loggingUserOut()}>
                    <ListItemIcon>
                      <Logout sx={{ color: 'primary.main' }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: 'primary.main' }}>
                      Log out
                    </ListItemText>
                    {isLoggingUserOut && <CircularProgress size="1em" />}
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Container>
      </Toolbar>

      <Toolbar
        sx={{
          display: { xs: 'flex', md: 'none' },
          marginTop: { xs: '5px', md: 0 },
        }}
        disableGutters
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            pl: 1,
            pt: '4px',
            borderTop: '1px solid',
            borderColor: 'primary.light',
          }}
        >
          <Button
            to="/ebook-deals"
            component={RouterLink}
            color="secondary"
            sx={{
              mr: 1,
              fontSize: '1rem',
              color: '#fff',
              textTransform: 'none',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
            }}
          >
            All eBooks
          </Button>
          <Button
            to="/ebook-deals/free-ebooks"
            component={RouterLink}
            color="secondary"
            sx={{
              mr: 1,
              fontSize: '1rem',
              color: '#fff',
              textTransform: 'none',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.2)' },
            }}
          >
            Free eBooks
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
