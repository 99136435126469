/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  checkStringPayload,
  handleErrors,
} from '../snacks/snacksSlice';
import { Category } from '../categories/categoriesSlice';

export interface EditReader {
  uuid?: string;
  reader: Reader;
}

export interface Reader {
  uuid?: string;
  email: string;
  category_ids?: Array<number>;
  categories?: Array<Category>;
  author_ids?: Array<number>;
  book_ids?: Array<number>;
}

interface GenericApiParams {
  uuid?: string;
  joins?: Array<string>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
}

const readersAdapter = createEntityAdapter<Reader>({
  selectId: (reader: Reader) => reader.uuid!,
});

export const getReader = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('readers/get', async (params: GenericApiParams, thunkApi: any) => {

  try {
    const response = await axiosInstance.get(`/api/v1/readers/${params.uuid}`, {
      params,
    });
    thunkApi.dispatch(readersSlice.actions.readerReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const createReader = createAsyncThunk<
  void, // Return type of the payload creator
  Reader, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('readers/create', async (reader: Reader, thunkApi: any) => {

  try {
    const response = await axiosInstance.post('/api/v1/readers', reader);
    thunkApi.dispatch(readersSlice.actions.readerReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updateReader = createAsyncThunk<
  void,
  EditReader,
  { rejectValue: Error }
>('readers/update', async (reader: EditReader, thunkApi: any) => {

  try {
    const response = await axiosInstance.patch(
      `/api/v1/readers/${reader.uuid}/`,
      reader.reader,
    );
    thunkApi.dispatch(readersSlice.actions.readerReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const readersSlice = createSlice({
  name: 'readers',
  initialState: readersAdapter.getInitialState({}),
  reducers: {
    /**
     * A reader was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * readers doesn't still show the reader whose status changed.
     */
    readerChanged: (state, action: PayloadAction<number>) => {
      readersAdapter.removeOne(state, action);
    },
    readersReceivedReplace: (state, action: PayloadAction<Reader[]>) => {
      checkStringPayload(action.payload);
      readersAdapter.setAll(state, action.payload);
    },
    readersReceivedAdd: (state, action: PayloadAction<Reader[]>) => {
      checkStringPayload(action.payload);
      readersAdapter.upsertMany(state, action.payload);
    },
    readerReceived: (state, action: PayloadAction<Reader>) => {
      readersAdapter.upsertOne(state, action.payload);
    },
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = readersAdapter.getSelectors<RootState>(
  (state) => state.readers
);
export const {
  selectAll: selectAllReaders,
  selectById: selectReaderById,
} = selectors;

export default readersSlice.reducer;
