import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BookIcon from '@mui/icons-material/Book';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DiscountIcon from '@mui/icons-material/Discount';
import { MenuItemType } from './menuItemsType';
import { Memory } from '@mui/icons-material';

const userMenuItems: MenuItemType[] = [
  {
    url: '/users/books',
    icon: BookIcon,
    text: 'Books',
    withDivider: true,
  },
  {
    url: '/users/promotions',
    icon: EventAvailableIcon,
    text: 'Promotions',
    withDivider: true,
  },
  {
    url: '/users/newsletters',
    icon: NewspaperIcon,
    text: 'Newsletters',
    withDivider: true,
  },
  {
    url: '/users/discounts',
    icon: DiscountIcon,
    text: 'Discounts',
    withDivider: true,
  },
  // We may reinstate this if we want a reporting dashboard
  // {
  //   url: '/stats',
  //   icon: TimelineIcon,
  //   text: 'Stats',
  //   withDivider: true,
  // },
  {
    // This is the session-based sign-in for the admin panel only
    url: '/u/users/sign_in',
    icon: AdminPanelSettingsIcon,
    text: 'Admin Panel',
    withDivider: true,
  },
  {
    url: '/users/ai',
    icon: Memory,
    text: 'Open Ai Prompt',
    withDivider: true,
  },
];

export default userMenuItems;
