/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import axiosInstance, { axios } from '../../utils/axios';
import { checkStringPayload, handleErrors } from '../snacks/snacksSlice';
import { SuperCategory } from '../super-categories/superCategoriesSlice';
import { RootState } from '../../app/store';

/**
 * This category type forms the response type for categories
 * when requested by the user. No other types are necessary since we don't
 * create categories via the interface.
 */
export interface Category {
  [index: string]: any;
  id: number;
  name: string;
  name_acronym: string;
  avg_price_free: number;
  avg_price_less_than_dollar: number;
  avg_price_one_to_two_dollar: number;
  avg_price_two_dollar_plus: number;
  free_ad_price: number;
  less_than_dollar_ad_price: number;
  one_to_two_dollar_ad_price: number;
  two_dollar_plus_ad_price: number;
  mailchimp_id: string;
  mailchimp_group_id: string;
  page_limit: number;
  subscriber_count: number;
  super_category_id: number;
  super_category: SuperCategory;
}

/**
 * Define a type for the partners, which we can cast down to
 * when dealing with the partners.
 */
export type ParterCategory = Pick<
  Category,
  | 'avg_price_free'
  | 'free_ad_price'
  | 'less_than_dollar_ad_price'
  | 'name'
  | 'name_acronym'
  | 'one_to_two_dollar_ad_price'
  | 'page_limit'
  | 'super_category'
  | 'super_category_id'
  | 'two_dollar_plus_ad_price'
>;

// todo cs - dry this up around the app
interface GenericApiParams {
  id?: number;
  resourceType: string;
  joins?: Array<string>;
  sort_col?: string;
  sort_dir?: string;
}

const categoriesAdapter = createEntityAdapter<Category>();

export const getAllCategories = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('categories/getAll', async (params, thunkApi: any) => {
  const headers = { 'resource-type': params.resourceType };
  try {
    const response = await axiosInstance.get('/api/v1/categories', {
      headers,
      params,
    });
    thunkApi.dispatch(categoriesSlice.actions.setCategories(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getCategory = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('categories/get', async (params, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };
  try {
    const response = await axiosInstance.get(`/api/v1/categories/${params.id}`, {
      headers,
    });
    thunkApi.dispatch(categoriesSlice.actions.setCategory(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: categoriesAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      checkStringPayload(action.payload);
      categoriesAdapter.setAll(state, action.payload);
    },
    setCategory: (state, action: PayloadAction<Category>) => {
      categoriesAdapter.upsertOne(state, action.payload);
    },
  },
});

const selectors = categoriesAdapter.getSelectors<RootState>(
  (state) => state.categories
);

export const { selectById: selectCategory, selectAll: selectAllCategories } = selectors;

export default categoriesSlice.reducer;
