import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import colorInitializer from './colors';

// You may use these theme colors by importing this theme file,
// then referencing colors like this:
// theme.palette.primary.main
// theme.palette.primary.light
// theme.palette.primary.dark
// Full list here:
// https://mui.com/customization/palette/#default-values

const tempTheme = createTheme({
  palette: {
    primary: {
      main: '#ff6d00',
    },
    secondary: {
      main: '#b35ec2',
    },
  },
  // TODO CS - Override all margins on header items
  // For example to fix the promotions page.
  // GRRRR none of these styles actually override anything.
  // Overriding the base element classes is strangely difficult
  typography: {
    h1: {
      // fontSize: '3.5rem',
    },
    h2: {
      // fontSize: '3.1rem',
    },
    h3: {
      // fontSize: '2.2rem',
    },
    h4: {
      // fontSize: '1.8rem',
    },
    h5: {
      // fontSize: '1.6rem',
    },
    h6: {
      // fontSize: '1.3rem',
    },
    fontFamily: ['Open Sans', 'Helvetica Neue', 'Arial', 'sans-serif1'].join(
      ','
    ),
  },
});

const theme = responsiveFontSizes(tempTheme);
export const colors = colorInitializer(theme);
export default theme;
