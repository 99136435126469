import React, { useState } from 'react';
import { Box, CssBaseline } from '@mui/material';
// Left in, in case we can get this working again.
// import { Box, CssBaseline } from '@mui/material';
import {
  Theme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import TrackingPixels from './Tracking/TrackingPixels';
import Router from './app/Router/Router';
import theme from './theme/theme';
import Snacks from './store/snacks/Snacks';
import ScrollToTop from './app/AppChecks/ScrollToTop';
import Navbar from './components/navbars/NavbarContainer';
import Sidebar from './components/navbars/Sidebar';
import Footer from './components/footer/Footer';
import AppContainer from './components/layout/AppContainer';
import RecordPageViews from './Tracking/RecordPageViews';
import TokenValidation from './app/AppChecks/TokenValidation';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Left in, in case we can get this working again.
// Minus ten here to offset some padding contained within
// the provider internals that can't be overridden
// const snackDistanceFromTop = constants.navbar.height + 4;

const App = function () {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <TrackingPixels />
        <Box
          component="div"
          className="snackbar-surrounder"
          sx={{
            '&.snackbar-surrounder .SnackbarContainer-root': {
              //  Use this to adjust how far the snack is from the top of the page
              top: '3px',
              maxWidth: '60%',
            },
            '& .SnackbarContent-root': {
              border: '2px solid #eee',
              '&.SnackbarItem-lessPadding': {
                pl: 1.5,
              },
            },
            minHeight: 'inherit',
            display: 'inherit',
            flexDirection: 'inherit',
          }}
        >
          <SnackbarProvider
            maxSnack={3}
            // Left in, for testing the snackbar.
            // Comment this in to keep the snack there for long.
            // autoHideDuration={100000}
            autoHideDuration={10 * 1000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <CssBaseline />
            <Snacks />
            <ScrollToTop />
            <RecordPageViews />
            <TokenValidation />
            <Box
              sx={{
                minHeight: '100vh',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Navbar />
              <AppContainer
                sidebar={
                  <Sidebar
                    sidebarOpen={sidebarOpen}
                    setSidebarOpen={setSidebarOpen}
                  />
                }
                content={<Router />}
              />
              <Footer sidebarOpen={sidebarOpen} />
            </Box>
          </SnackbarProvider>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
