import { Box, Container } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import constants from '../../theme/constants';
import { selectCurrentUser } from '../../store/current-user/currentUserSlice';
import { selectCurrentPartner } from '../../store/current-partner/currentPartnerSlice';
import {
  requiresPartnerAuth,
  requiresUserAuth,
  slashStrippedPath,
} from '../../app/Router/routes';
import { useAppSelector } from '../../app/hooks';
import LoadingSpinner from '../misc/LoadingSpinner';

const AppContainer = function ({
  sidebar,
  content,
}: {
  sidebar: React.ReactNode;
  content: React.ReactNode;
}) {
  const location = useLocation();
  const currentPartner = useAppSelector(selectCurrentPartner)[0];
  const currentUser = useAppSelector(selectCurrentUser)[0];

  /**
   * For both the partner and user:
   * - Check if the route requires auth
   * - If yes, then check if there's a user logged in, or if they're loading
   */
  const authNotYetVerified = () => {
    if (
      requiresPartnerAuth(location.pathname) &&
      (!currentPartner || currentPartner.isLoading)
    ) {
      return true;
    }

    if (
      requiresUserAuth(location.pathname) &&
      (!currentUser || currentUser.isLoading)
    ) {
      return true;
    }

    return false;
  };

  /**
   * Check if the user is on some landing page.
   * Used to determine whether to wrap the content in a container or not.
   */
  const noContainer = () =>
    slashStrippedPath(location.pathname) === '' ||
    slashStrippedPath(location.pathname) === '/partners' ||
    slashStrippedPath(location.pathname).startsWith('/ebook-deals') ||
    slashStrippedPath(location.pathname).startsWith('/reader-blog') ||
    slashStrippedPath(location.pathname).startsWith('/books') ||
    slashStrippedPath(location.pathname).startsWith('/authors') ||
    slashStrippedPath(location.pathname).startsWith('/categories/')

  /**
   * Render the content by either wrapping in a container or not
   */
  const renderContent = () => {
    if (noContainer()) {
      return <Box sx={{ width: '100%' }}>{content}</Box>;
    }

    return (
      <Container maxWidth="xl" sx={{ pt: 4, mb: 12 }}>
        {content}
      </Container>
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        alignItems: 'stretch',
        marginTop: { xs: `${constants.navbar.mobileHeight}px`, md: `${constants.navbar.height}px` },
      }}
    >
      <Box sx={{ display: 'flex', width: '100%' }}>
        {sidebar}
        {authNotYetVerified() ? <LoadingSpinner /> : renderContent()}
      </Box>
    </Box>
  );
};

export default AppContainer;
