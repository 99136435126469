import React from 'react';
import { CSSObject, styled } from '@mui/material/styles';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link as ExternalLink,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import constants from '../../theme/constants';
import theme from '../../theme/theme';
import partnerMenuItems from '../../theme/partnerMenuItems';
import userMenuItems from '../../theme/userMenuItems';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  isLoggedInPartner,
  logoutPartner,
} from '../../store/current-partner/currentPartnerSlice';
import {
  isLoggedInUser,
  logoutUser,
} from '../../store/current-user/currentUserSlice';
import NewslettersSidebarItems from './NewslettersSidebarItems';
import { showWithSidebarIfAvailable } from '../../app/Router/routes';

// The following code taken from the mui example here:
// https://mui.com/components/drawers/#mini-variant-drawer
const openedMixin = (): CSSObject => ({
  flex: `0 0 ${constants.sidebar.expanded.width}`,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (): CSSObject => ({
  overflowX: 'hidden',
  flex: `0 0 ${constants.sidebar.collapsed.width}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
});

const Drawer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }: { open: boolean }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderRight: '1px solid #DDD',
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  ...(open
    ? {
        ...openedMixin(),
        '& .MuiDrawer-paper': openedMixin(),
      }
    : {}),
  ...(!open
    ? {
        ...closedMixin(),
        '& .MuiDrawer-paper': closedMixin(),
      }
    : {}),
}));

const classes = {
  icon: {
    minWidth: constants.sidebar.expanded.iconWidth,
  },
  iconCollapsed: {
    minWidth: constants.sidebar.collapsed.iconWidth,
  },
};

const Sidebar = function (
  { sidebarOpen, setSidebarOpen }:
  { sidebarOpen: boolean; setSidebarOpen: (open: boolean) => void }
) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userLoggedIn = useAppSelector(isLoggedInUser);
  const partnerLoggedIn = useAppSelector(isLoggedInPartner);
  const [isLoggingPartnerOut, setIsLoggingPartnerOut] = React.useState(false);
  const [isLoggingUserOut, setIsLoggingUserOut] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(false);

  const loggingPartnerOut = (redirectOut: boolean) => {
    setIsLoggingPartnerOut(true);
    dispatch(logoutPartner())
      .unwrap()
      .then(() => {
        if (!redirectOut) return;
        console.log('Redirecting to partner sign out');
        dispatch(push('/partners/sign-in'));
      })
      .finally(() => setIsLoggingPartnerOut(false));
  };

  const loggingUserOut = () => {
    setIsLoggingUserOut(true);
    dispatch(logoutUser())
      .unwrap()
      .then(() => {
        dispatch(push('/users/sign-in'));
      })
      .finally(() => setIsLoggingUserOut(false));
  };

  // Determine whether to show the sidebar or not,
  // based on the pathname and the currently logged in user
  React.useEffect(() => {
    if (!showWithSidebarIfAvailable(location.pathname)) {
      setShowSidebar(false);
      return;
    }
    setShowSidebar(userLoggedIn || partnerLoggedIn);
  }, [location, userLoggedIn, partnerLoggedIn]);

  React.useEffect(
    () => () => {
      setIsLoggingUserOut(false);
      setIsLoggingPartnerOut(false);
    },
    []
  );

  if (!showSidebar) return null;

  const navItems = userLoggedIn ? userMenuItems : partnerMenuItems;

  return (
    <Drawer className="sidebar" open={sidebarOpen}>
      <Divider />
      <List
        sx={{
          position: 'fixed',
          overflowX: 'hidden',
          overflowY: 'scroll',
          height: {
            xs: `calc(100vh - ${constants.navbar.mobileHeight}px)`,
            md: `calc(100vh - ${constants.navbar.height}px)`,
          },
          ...(sidebarOpen
            ? {
                width: constants.sidebar.expanded.width,
              }
            : {
                width: constants.sidebar.collapsed.width,
              }),
        }}
      >
        {navItems.map((listItem) => {
          if (listItem.text === 'Newsletters') {
            return (
              <NewslettersSidebarItems
                sidebarOpen={sidebarOpen}
                key="Newsletters"
              />
            );
          }
          if (listItem.text === 'Admin Panel') {
            return (
              <div key={listItem.text}>
                <ListItemButton
                  component={ExternalLink}
                  href={listItem.url}
                  disabled={location.pathname === listItem.url}
                >
                  <ListItemIcon
                    sx={sidebarOpen ? classes.icon : classes.iconCollapsed}
                  >
                    {React.createElement(listItem.icon, {
                      color:
                        location.pathname === listItem.url
                          ? 'primary'
                          : undefined,
                    })}
                  </ListItemIcon>
                  <ListItemText primary={listItem.text} />
                </ListItemButton>
                {listItem.withDivider && <Divider />}
              </div>
            );
          }
          return (
            <div key={listItem.text}>
              <ListItemButton
                component={Link}
                to={listItem.url}
                disabled={location.pathname === listItem.url}
              >
                <ListItemIcon
                  sx={sidebarOpen ? classes.icon : classes.iconCollapsed}
                >
                  {React.createElement(listItem.icon, {
                    color:
                      location.pathname === listItem.url
                        ? 'primary'
                        : undefined,
                  })}
                </ListItemIcon>
                <ListItemText primary={listItem.text} />
              </ListItemButton>
              {listItem.withDivider && <Divider />}
            </div>
          );
        })}
        <ListItemButton
          className="logout-button"
          onClick={() =>
            userLoggedIn ? loggingUserOut() : loggingPartnerOut(true)
          }
        >
          <ListItemIcon sx={sidebarOpen ? classes.icon : classes.iconCollapsed}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log out" />
          {(isLoggingPartnerOut || isLoggingUserOut) && (
            <CircularProgress size="1em" />
          )}
        </ListItemButton>
        <Divider />
        <ListItemButton onClick={() => setSidebarOpen(!sidebarOpen)}>
          <ListItemIcon sx={sidebarOpen ? classes.icon : classes.iconCollapsed}>
            <ExpandMoreIcon
              sx={{
                transform: sidebarOpen ? 'rotate(90deg)' : 'rotate(270deg)',
                transition: constants.sidebar.transitionTime,
              }}
            />
          </ListItemIcon>
          <ListItemText
            className="collapse-expand"
            color="text.secondary"
            primary={sidebarOpen ? 'Collapse' : 'Expand'}
          />
        </ListItemButton>
        {userLoggedIn && partnerLoggedIn && (
          <>
            <Divider />
            <ListItemText sx={{ py: 1, px: 3, whiteSpace: 'normal' }}>
              You&apos;re logged in as a partner as well.
              <Box
                sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                onClick={() => loggingPartnerOut(false)}
              >
                Log out from partner
              </Box>
            </ListItemText>
          </>
        )}
      </List>
    </Drawer>
  );
};

export default Sidebar;
