/* eslint-disable import/no-named-default */
import { default as axiosImport } from 'axios';

export const axios = axiosImport;

// Axios instance now exported so that we can add config
// values to it.
export default axios.create({
  baseURL: process.env.REACT_APP_BOOKRAID_API_URL || '',
});
