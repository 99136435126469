/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axiosInstance, { axios } from '../../utils/axios';

import { checkStringPayload, handleErrors } from '../snacks/snacksSlice';

export interface ReaderBlog {
  // This index signature allows us to reference a key from a readerBlog object dynamically,
  // like with: readerBlog['amazon'+'_url']
  [index: string]: any;
  id?: number | null;
  parsed_title: string;
  canonical_url_title: string;
  content: string;
  status: string;
  published_at?: Date;
  updated_at?: Date;
  created_at?: Date;
  image_urls?: Array<string> | null;
  content_sanitized?: string;
  truncated_content?: string;
  word_count?: number;
  base_generated_content?: any;
}

const readerBlogsAdapter = createEntityAdapter<ReaderBlog>({});

interface GenericApiParams {
  id?: number;
  resourceType?: string;
  public?: boolean;
  query?: string;
  joins?: Array<any>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
}

export const getReaderBlog = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('readerBlogs/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(`/api/v1/reader_blogs/${params.id}`, {
      headers: { 'resource-type': params.resourceType },
      params: {
        public: params.public,
        joins: params.joins,
      },
    });
    thunkApi.dispatch(readerBlogsSlice.actions.readerBlogReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getAllReaderBlogs = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('readerBlogs/getAll', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get('/api/v1/reader_blogs', {
      params,
      headers: { 'resource-type': params.resourceType },
    });
    thunkApi.dispatch(readerBlogsSlice.actions.readerBlogsReceived(response.data.reader_blogs));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const readerBlogsSlice = createSlice({
  name: 'readerBlog',
  initialState: readerBlogsAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    readerBlogReceived: (state, action: PayloadAction<ReaderBlog>) => {
      checkStringPayload(action.payload);
      readerBlogsAdapter.upsertOne(state, action.payload);
    },
    readerBlogsReceived: (state, action: PayloadAction<ReaderBlog[]>) => {
      checkStringPayload(action.payload);
      readerBlogsAdapter.setAll(state, action.payload);
    },
    removeAllReaderBlogs: (state) => {
      readerBlogsAdapter.removeAll(state);
    }
  },
});

const selectors = readerBlogsAdapter.getSelectors<RootState>((state) => state.readerBlogs);
export const { selectById: selectReaderBlog, selectAll: selectAllReaderBlogs } = selectors;

export default readerBlogsSlice.reducer;
