/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';

import { handleErrors } from '../snacks/snacksSlice';

// checkStringPayload

export interface Newsletter {
  id: number;
  send_date: string;
  promotions: [];
  days_since_data?: { [id: number]: number };
}

// interface NewslettersState {
//   newsletters: Newsletter[];
//   newsletter: Newsletter;
//   newslettersLoading: boolean;
// }

// const initialState: NewslettersState = {
//   newsletters: [],
//   newsletter: {
//     id: null,
//     send_date: '',
//     promotions: [],
//   },
//   newslettersLoading: true,
// };

const newslettersAdapter = createEntityAdapter<Newsletter>({
  sortComparer: (a: Newsletter, b: Newsletter) => a.id - b.id,
});

export const getAllNewsletters = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('newsletters/getAll', async (funcParams, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.get('/api/v1/newsletters', {
      params: funcParams.params,
      headers,
    });
    thunkApi.dispatch(
      newslettersSlice.actions.newslettersReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getNewsletter = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('newsletters/get', async (funcParams, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.get(
      `/api/v1/newsletters/${funcParams.id}`,
      {
        headers,
        params: funcParams.params,
      }
    );
    thunkApi.dispatch(
      newslettersSlice.actions.newsletterReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const newslettersSlice = createSlice({
  name: 'newsletters',
  initialState: newslettersAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    newslettersReceived: (state, action: PayloadAction<Newsletter[]>) => {
      newslettersAdapter.upsertMany(state, action.payload);
    },
    newsletterReceived: (state, action: PayloadAction<Newsletter>) => {
      newslettersAdapter.upsertOne(state, action.payload);
    },
  },
});

const selectors = newslettersAdapter.getSelectors<RootState>(
  (state) => state.newsletters
);
export const { selectAll: selectAllNewsletters, selectById: selectNewsletter } =
  selectors;

export default newslettersSlice.reducer;
