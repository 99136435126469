/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';

import { handleErrors } from '../snacks/snacksSlice';

// Interface is the same for users and partners
export interface Tag {
  id: number;
  name: string;
}

const tagsAdapter = createEntityAdapter<Tag>({
  sortComparer: (a: Tag, b: Tag) => a.id - b.id,
});

// todo cs - dry this up around the app
interface GenericApiParams {
  resourceType: string;
  joins?: Array<string>;
  sort_col?: string;
  sort_dir?: string;
}

export const getAllTags = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('tags/getAll', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get('/api/v1/tags', {
      headers: { 'resource-type': params.resourceType },
      params,
    });
    thunkApi.dispatch(
      tagsSlice.actions.tagsReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getTag = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('tags/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/tags/${params.id}`,
      {
        headers: { 'resource-type': params.resourceType },
        params: params.params,
      }
    );

    thunkApi.dispatch(
      tagsSlice.actions.tagReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const createTag = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('tag/create', async (tag: Tag, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.post(`/api/v1/tags`, tag, {
      headers,
    });
    thunkApi.dispatch(tagsSlice.actions.tagReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const tagsSlice = createSlice({
  name: 'tags',
  initialState: tagsAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    tagsReceived: (
      state,
      action: PayloadAction<Tag[]>
    ) => {
      tagsAdapter.upsertMany(state, action.payload);
    },
    tagReceived: (state, action: PayloadAction<Tag>) => {
      tagsAdapter.upsertOne(state, action.payload);
    },
  },
});

const selectors = tagsAdapter.getSelectors<RootState>(
  (state) => state.tags
);
export const { selectAll: selectAllTags } = selectors;

export default tagsSlice.reducer;
