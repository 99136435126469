import React from 'react';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

const StyledLoadingSpinner = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '5rem',
}));

const LoadingSpinner = function () {
  return (
    <StyledLoadingSpinner>
      <CircularProgress />
    </StyledLoadingSpinner>
  );
};

export default LoadingSpinner;
