/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  checkStringPayload,
  handleErrors,
} from '../snacks/snacksSlice';

export interface PartnerHelpful {
  id: number;
  promotion_site_review_id: number;
  partner_id: number
  resourceType: string;
}

export interface NewPartnerHelpful {
  id?: number;
  promotion_site_review_id: number;
  resourceType: string;
}

export interface EditPartnerHelpful {
  id?: number;
  promotion_site_review_id: number;
  resourceType: string;
}

/**
 * Because we store reviewed and unreviewed partnerHelpfuls which
 * appear in separate lists, we have to sort based on reviewed_at
 * when they have been reviewed, and otherwise sort on requested_date.
 *
 * TODO CS - the sorting strategy depends on the request being made.
 * For example, the "Last 20 reviewed partnerHelpfuls" for users should
 * be sorted by reviewed_at DESC
 * By contrast, the partner's past partnerHelpfuls should be sorted by
 * the partnerHelpful date if it exists, or the requested date if the partnerHelpful
 * date wasn't set. It should have no notion of the requeted_at.
 * This divergence in strategies is accommodated for in our current architecture.
 */
const partnerHelpfulsAdapter = createEntityAdapter<PartnerHelpful>();

export const createPartnerHelpful = createAsyncThunk<
  void, // Return type of the payload creator
  NewPartnerHelpful, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('partnerHelpfuls/create', async (partnerHelpful: NewPartnerHelpful, thunkApi: any) => {
  const headers = { 'resource-type': partnerHelpful.resourceType };

  try {
    const response = await axiosInstance.post('/api/v1/partner_helpfuls', partnerHelpful, {
      headers,
    });
    thunkApi.dispatch(partnerHelpfulsSlice.actions.partnerHelpfulReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, partnerHelpful.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const partnerHelpfulsSlice = createSlice({
  name: 'partnerHelpfuls',
  initialState: partnerHelpfulsAdapter.getInitialState({}),
  reducers: {
    /**
     * A partnerHelpful was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * partnerHelpfuls doesn't still show the partnerHelpful whose status changed.
     */
    partnerHelpfulChanged: (state, action: PayloadAction<number>) => {
      partnerHelpfulsAdapter.removeOne(state, action);
    },
    partnerHelpfulsReceivedReplace: (state, action: PayloadAction<PartnerHelpful[]>) => {
      checkStringPayload(action.payload);
      partnerHelpfulsAdapter.setAll(state, action.payload);
    },
    partnerHelpfulsReceivedAdd: (state, action: PayloadAction<PartnerHelpful[]>) => {
      checkStringPayload(action.payload);
      partnerHelpfulsAdapter.upsertMany(state, action.payload);
    },
    partnerHelpfulReceived: (state, action: PayloadAction<PartnerHelpful>) => {
      partnerHelpfulsAdapter.upsertOne(state, action.payload);
    },
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = partnerHelpfulsAdapter.getSelectors<RootState>(
  (state) => state.partnerHelpfuls
);
export const {
  selectAll: selectAllPartnerHelpfuls,
  selectById: selectPartnerHelpfulById,
} = selectors;

export default partnerHelpfulsSlice.reducer;
