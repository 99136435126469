/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import {
  handleErrors,
} from '../snacks/snacksSlice';
import { createPromotionSiteReview, PromotionSiteReview, updatePromotionSiteReview } from '../promotion-site-reviews/promotionSiteReviewsSlice';


export interface PromotionSite {
  [key: string]: any;
  id: number;
  title: string;
  description: string;
  url: string;
  accepts_free: boolean; 
  accepts_paid: boolean; 
  mailing_list_size: number;
  max_price_in_cents: number;
  min_price_in_cents: number;
  average_stars: number;
  total_stars: number;
  weighted_rating: number;
  promotable_websites: Array<string>;
  promotion_site_reviews: Array<PromotionSiteReview>;
  related_promotion_sites: Array<PromotionSite>;
}

interface GenericApiParams {
  joins?: Array<string>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
  resourceType?: string;
}

interface PromotionSiteParams extends GenericApiParams {
  id?: number;
}

const promotionSitesAdapter = createEntityAdapter<PromotionSite>();

export const getAllPromotionSites = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotionSites/get', async (params: GenericApiParams, thunkApi: any) => {
  const headers = { 'resource-type': params.resourceType };

  try {
    const response = await axiosInstance.get('/api/v1/promotion_sites', {
      headers,
      params,
    });

    thunkApi.dispatch(
      promotionSitesSlice.actions.promotionSitesReceivedReplace(
        response.data
      )
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getPromotionSite = createAsyncThunk<
  void, // Return type of the payload creator
  PromotionSiteParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('promotionSite/get', async (params: PromotionSiteParams, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.get(`/api/v1/promotion_sites/${params.id}`, {
      params,
      headers,
    });
    thunkApi.dispatch(promotionSitesSlice.actions.promotionSiteReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const promotionSitesSlice = createSlice({
  name: 'promotionSites',
  initialState: promotionSitesAdapter.getInitialState({}),
  reducers: {
    /**
     * A promotionSite was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * promotionSites doesn't still show the promotionSite whose status changed.
     */
    promotionSitesReceivedAdd: (state, action: PayloadAction<PromotionSite[]>) => {
      // checkStringPayload(action.payload);
      promotionSitesAdapter.upsertMany(state, action.payload);
    },
    promotionSitesReceivedReplace: (state, action: PayloadAction<PromotionSite[]>) => {
      // checkStringPayload(action.payload);
      promotionSitesAdapter.setAll(state, action.payload);
    },
    promotionSiteReceived: (state, action: PayloadAction<PromotionSite>) => {
      promotionSitesAdapter.upsertOne(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPromotionSiteReview.fulfilled, (state, action: PayloadAction<any>) => {
      promotionSitesAdapter.upsertOne(state, action.payload.promotion_site);
    });
    builder.addCase(updatePromotionSiteReview.fulfilled, (state, action: PayloadAction<any>) => {
      promotionSitesAdapter.upsertOne(state, action.payload.promotion_site);
    });
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = promotionSitesAdapter.getSelectors<RootState>(
  (state) => state.promotionSites
);
export const {
  selectAll: selectAllPromotionSites,
  selectById: selectPromotionSiteById,
} = selectors;

export default promotionSitesSlice.reducer;
