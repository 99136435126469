/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import { addSnack, handleErrors } from '../snacks/snacksSlice';

export interface CreditCard {
  id?: number;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  zip_code: string;
  is_default: boolean;
  created_at: string;
  is_card_valid: boolean;
}

const creditCardsAdapter = createEntityAdapter<CreditCard>({
  sortComparer: (a: CreditCard, b: CreditCard) =>
    Date.parse(b.created_at) - Date.parse(a.created_at),
});

/**
 * Check if we have any valid credit card.
 * Iterates the ids and uses the ids to iterate the entities, checking each.
 */
export const hasValidCreditCard = (state: RootState) =>
  !!state.creditCards.ids.find(
    (id: number) => !!state.creditCards.entities[id]?.is_card_valid
  );

export const getAllCreditCards = createAsyncThunk<
  void, // Return type of the payload creator
  void, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('creditCards/get', async (params, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.get('/api/v1/credit_cards', {
      headers,
      params,
    });

    thunkApi.dispatch(
      creditCardsSlice.actions.creditCardsReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const createCreditCard = createAsyncThunk<
  void, // Return type of the payload creator
  string, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('creditCards/create', async (token: string, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.post(
      '/api/v1/credit_cards',
      { stripe_card_token: token },
      { headers }
    );
    thunkApi.dispatch(
      addSnack({
        message: 'Your credit card was successfully added!',
        variant: 'success',
      })
    );
    thunkApi.dispatch(
      creditCardsSlice.actions.creditCardChanged(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updateCreditCard = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('creditCards/update', async (creditCard: any, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.patch(
      `/api/v1/credit_cards/${creditCard.id}`,
      creditCard,
      {
        headers,
      }
    );
    thunkApi.dispatch(
      creditCardsSlice.actions.creditCardChanged(response.data)
    );
    thunkApi.dispatch(
      addSnack({
        message: 'Your credit card was successfully updated!',
        variant: 'success',
      })
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updateDefaultCreditCard = createAsyncThunk<
  void, // Return type of the payload creator
  number, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('creditCards/updateDefault', async (creditCardId: number, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.patch(
      `/api/v1/credit_cards/${creditCardId}/make_default`,
      {},
      {
        headers,
      }
    );
    thunkApi.dispatch(
      creditCardsSlice.actions.creditCardsReceived(response.data)
    );
    thunkApi.dispatch(
      addSnack({
        message: 'The default credit card was successfully updated',
        variant: 'success',
      })
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const deleteCreditCard = createAsyncThunk<
  void, // Return type of the payload creator
  number, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('creditCards/create', async (creditCardId: number, thunkApi: any) => {
  const headers = { 'resource-type': 'partner' };

  try {
    const response = await axiosInstance.delete(
      `/api/v1/credit_cards/${creditCardId}`,
      {
        headers,
      }
    );
    thunkApi.dispatch(creditCardsSlice.actions.creditCardRemoved(creditCardId));
    thunkApi.dispatch(
      addSnack({
        message: 'Your credit card was successfully removed!',
        variant: 'success',
      })
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'partner');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const creditCardsSlice = createSlice({
  name: 'creditCards',
  initialState: creditCardsAdapter.getInitialState({}),
  reducers: {
    /**
     * A creditCard was changed, so remove it from the list.
     *
     * Whichever component loads next will grab it.
     * This is required to ensure that navigating back to the list of
     * creditCards doesn't still show the creditCard whose status changed.
     */
    creditCardsReceived: (state, action: PayloadAction<CreditCard[]>) => {
      creditCardsAdapter.upsertMany(state, action.payload);
    },
    creditCardChanged: (state, action: PayloadAction<CreditCard>) => {
      creditCardsAdapter.upsertOne(state, action.payload);
    },
    creditCardRemoved: (state, action: PayloadAction<number>) => {
      creditCardsAdapter.removeOne(state, action);
    },
  },
});

// Other actions can appear here, such as:
// https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions

const selectors = creditCardsAdapter.getSelectors<RootState>(
  (state) => state.creditCards
);
export const { selectAll: selectAllCreditCards } = selectors;

export default creditCardsSlice.reducer;
