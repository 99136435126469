import React from 'react';
import { Box, Grid, Link, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import MuiRouterLink from '../mui-wrappers/MuiRouterLink';
import theme, { colors } from '../../theme/theme';
import {
  isLoggedInUser,
  logoutUser,
} from '../../store/current-user/currentUserSlice';
import { isLoggedInPartner } from '../../store/current-partner/currentPartnerSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { footerSidebarSquish } from '../../app/Router/routes';
import constants from '../../theme/constants';

const classes = {
  ul: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
  ulRight: {
    listStyleType: 'none',
    paddingLeft: 0,
  },
  a: {
    fontWeight: 'bold',
    display: 'block',
    textDecoration: 'none',
    padding: '6px 12px',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
};

const footerLinks = [
  [
    {
      title: 'Homepage',
      path: '/',
    },
    {
      title: 'Partner with BookRaid',
      path: '/partners',
    },
    {
      title: 'Promotion Requirements',
      path: '/promotion-requirements',
    },
    { title: 'Pricing', path: '/pricing' },
    {
      title: 'Subscribers and Statistics',
      path: '/subscribers-and-statistics',
    },
    {
      title: 'BookRaid Social Media Icons',
      path: '/social-media-icons',
    },
    { title: 'Best Book Promotion Sites', path: '/best-book-promotion-sites' },
    { title: 'Best E-Readers of 2023', path: '/products/best-e-readers-2023' },
  ],
  [
    { title: 'Author Resources', path: '/author-resources' },
    { title: 'Contact', path: '/contact' },
    {
      title: 'Terms and Conditions',
      path: '/terms-and-conditions',
    },
    { title: 'Privacy', path: '/privacy' },
    { title: 'About', path: '/about' },
    { title: 'Blog', path: '/blog' },
    { title: 'Reader Blog', path: '/reader-blog' },
    { title: 'Book Marketing Guide', path: '/marketing-guide/how-to-promote-your-book' },
  ],
];

const Footer = function ({ sidebarOpen }: { sidebarOpen: boolean }) {
  const location = useLocation();
  const userLoggedIn = useAppSelector(isLoggedInUser);
  const partnerLoggedIn = useAppSelector(isLoggedInPartner);
  const dispatch = useAppDispatch();
  const [showSidebar, setShowSidebar] = React.useState(false);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const logUserOutFn = () => dispatch(logoutUser());

  // Determine whether to show the sidebar or not,
  // based on the pathname and the currently logged in user
  React.useEffect(() => {
    if (!footerSidebarSquish(location.pathname)) {
      setShowSidebar(false);
      return;
    }
    setShowSidebar(userLoggedIn || partnerLoggedIn);
  }, [location, userLoggedIn, partnerLoggedIn]);

  const mlWidth = () => {
    if (!showSidebar || smDown) {
      return 0;
    }
    if (sidebarOpen) {
      return constants.sidebar.expanded.width;
    }
    return constants.sidebar.collapsed.width;
  };

  return (
    <Box
      className="footer"
      sx={{
        border: 0,
        background: colors.footer.background,
        boxShadow: colors.footer.boxShadow,
        borderTop: colors.footer.border,
        color: 'white',
        padding: '0 15px 80px',
        marginTop: 'auto',
        zIndex: theme.zIndex.drawer - 1,
        flexShrink: 0,
        ml: mlWidth(),
      }}
    >
      {/* <Box sx={{ ...classes.footer, ...sidebarClass }}> */}
      <Grid container spacing={3}>
        {footerLinks.map((footerLinkGroup, i) => (
          // This strange key notation reads like:
          // For the key, use the title of the first element in the group.
          // This works well for nested arrays.
          <Grid item xs={6} sm={4} key={footerLinkGroup[i].title}>
            <Box component="ul" sx={classes.ul}>
              {footerLinkGroup.map(({ title, path }) => (
                <li key={title}>
                  <MuiRouterLink to={path} sx={classes.a} color="inherit">
                    {title}
                  </MuiRouterLink>
                </li>
              ))}
            </Box>
          </Grid>
        ))}
        <Grid item xs={12} sm={4}>
          <Box component="ul" sx={classes.ul}>
            <Box component="li" textAlign="right">
              {!userLoggedIn && !partnerLoggedIn && (
                <>
                  <MuiRouterLink
                    to="/partners/sign-in"
                    sx={classes.a}
                    color="inherit"
                  >
                    Partners: Sign in
                  </MuiRouterLink>
                  &nbsp;or&nbsp;&nbsp;&nbsp;&nbsp;
                  <MuiRouterLink
                    to="/partners/sign-up"
                    sx={classes.a}
                    color="inherit"
                  >
                    Sign up
                  </MuiRouterLink>
                </>
              )}
              {userLoggedIn && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link sx={classes.a} color="inherit" onClick={logUserOutFn}>
                  Log out
                </Link>
              )}
              <MuiRouterLink
                to="/authors"
                sx={classes.a}
                color="inherit"
              >
                All Authors
              </MuiRouterLink>
              <MuiRouterLink
                to="/books"
                sx={classes.a}
                color="inherit"
              >
                All Books
              </MuiRouterLink>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
