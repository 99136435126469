/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance, { axios } from '../../utils/axios';
import { RootState } from '../../app/store';
import { Category } from '../categories/categoriesSlice';

import { handleErrors } from '../snacks/snacksSlice';

// Interface is the same for users and partners
export interface SuperCategory {
  id: number;
  name: string;
  categories: Category[];
}

const superCategoriesAdapter = createEntityAdapter<SuperCategory>({
  sortComparer: (a: SuperCategory, b: SuperCategory) => a.id - b.id,
});

// todo cs - dry this up around the app
interface GenericApiParams {
  resourceType: string;
  joins?: Array<string>;
  sort_col?: string;
  sort_dir?: string;
}

export const getAllSuperCategories = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('superCategories/getAll', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get('/api/v1/super_categories', {
      headers: { 'resource-type': params.resourceType },
      params,
    });
    thunkApi.dispatch(
      superCategoriesSlice.actions.superCategoriesReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getSuperCategory = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('superCategories/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(
      `/api/v1/super_categories/${params.id}`,
      {
        headers: { 'resource-type': params.resourceType },
        params: params.params,
      }
    );

    thunkApi.dispatch(
      superCategoriesSlice.actions.superCategoryReceived(response.data)
    );
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const superCategoriesSlice = createSlice({
  name: 'superCategories',
  initialState: superCategoriesAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    superCategoriesReceived: (
      state,
      action: PayloadAction<SuperCategory[]>
    ) => {
      superCategoriesAdapter.upsertMany(state, action.payload);
    },
    superCategoryReceived: (state, action: PayloadAction<SuperCategory>) => {
      superCategoriesAdapter.upsertOne(state, action.payload);
    },
  },
});

const selectors = superCategoriesAdapter.getSelectors<RootState>(
  (state) => state.superCategories
);
export const { selectAll: selectAllSuperCategories } = selectors;

export default superCategoriesSlice.reducer;
