/* eslint-disable import/no-cycle */
import Cookies from 'universal-cookie';
import { combineReducers, Reducer } from 'redux';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import userReducer from '../store/current-user/currentUserSlice';
import partnerReducer from '../store/current-partner/currentPartnerSlice';
import authorsReducer from '../store/authors/authorsSlice';
import blogsReducer from '../store/blogs/blogsSlice';
import readerBlogsReducer from '../store/reader-blogs/readerBlogsSlice';
import booksReducer from '../store/books/booksSlice';
import promotionsReducer from '../store/promotions/promotionsSlice';
import promotionSitesReducer from '../store/promotion-sites/promotionSitesSlice';
import partnerHelpfulsReducer from '../store/partner-helpfuls/partnerHelpfulsSlice';
import partnerNotHelpfulsReducer from '../store/partner-not-helpfuls/partnerNotHelpfulsSlice';
import promotionSiteReviewsReducer from '../store/promotion-site-reviews/promotionSiteReviewsSlice';
import discountsReducer from '../store/discount-codes/discountCodesSlice';
import categoriesReducer from '../store/categories/categoriesSlice';
import superCategoriesReducer from '../store/super-categories/superCategoriesSlice';
import newslettersReducer from '../store/newsletters/newslettersSlice';
import newsletterPromoCountsReducer from '../store/newsletters/newsletterPromoCountsSlice';
import creditCardsReducer from '../store/credit-cards/creditCardsSlice';
import readersReducer from '../store/readers/readersSlice';
import snacksReducer from '../store/snacks/snacksSlice';
import tagsReducer from '../store/tags/tagsSlice';

const cookies = new Cookies();
export const history = createBrowserHistory();

// We combine reducers here to nest them under a root reducer
const combinedReducer = combineReducers({
  authors: authorsReducer,
  blogs: blogsReducer,
  categories: categoriesReducer,
  superCategories: superCategoriesReducer,
  currentUser: userReducer,
  currentPartner: partnerReducer,
  promotions: promotionsReducer,
  promotionSites: promotionSitesReducer,
  promotionSiteReviews: promotionSiteReviewsReducer,
  partnerHelpfuls: partnerHelpfulsReducer,
  partnerNotHelpfuls: partnerNotHelpfulsReducer,
  discounts: discountsReducer,
  newsletters: newslettersReducer,
  newsletterPromoCounts: newsletterPromoCountsReducer,
  creditCards: creditCardsReducer,
  books: booksReducer,
  readers: readersReducer,
  readerBlogs: readerBlogsReducer,
  snacks: snacksReducer,
  tags: tagsReducer,
  router: connectRouter(history) as Reducer,
});

/**
 * This root reducer runs on every store action, such as:
 * promotions/get/pending
 * promotions/promotionsReceivedAdd
 * promotions/get/fulfilled
 *
 * We use it here to listen for any logout event. In such an event,
 * we clear the store by setting the state to undefined.
 *
 * This is important so that we don't bleed the store
 * from one partner to another on logout/login.
 *
 * It's done for the user as well, just for completeness.
 */
const rootReducer = (state: any, action: any): any => {
  // Comment this in to see the actions streaming through
  // This console log left in cause it's so damn useful.
  // console.log('action', action);

  if (
    ['user/logout/fulfilled', 'partner/logout/fulfilled'].includes(action.type)
  ) {
    // eslint-disable-next-line no-param-reassign
    state = undefined;

    // Any cookies used should be unset here
    cookies.set('promotions-search', '');
    cookies.set('books-search', '');
  }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
