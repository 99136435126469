/* eslint-disable import/no-cycle */
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import axiosInstance, { axios } from '../../utils/axios';

import { checkStringPayload, handleErrors } from '../snacks/snacksSlice';

export interface Tag {
  name: string;
  id: number;
}

export interface Blog {
  // This index signature allows us to reference a key from a blog object dynamically,
  // like with: blog['amazon'+'_url']
  [index: string]: any;
  id?: number | null;
  title: string;
  content: string;
  status: string;
  tags?: Array<Tag> | null;
  tag_ids?: Array<number> | null;
  published_at?: Date;
  updated_at?: Date;
  created_at?: Date;
  image_urls?: Array<string> | null;
  content_sanitized?: string;
  truncated_content?: string;
  word_count?: number;
}

const blogsAdapter = createEntityAdapter<Blog>({});

interface GenericApiParams {
  id?: number;
  resourceType?: string;
  public?: boolean;
  query?: string;
  joins?: Array<any>;
  sort_col?: string;
  sort_dir?: string;
  page?: number;
  per?: number;
}

export const getBlog = createAsyncThunk<
  void, // Return type of the payload creator
  GenericApiParams, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('blogs/get', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get(`/api/v1/blogs/${params.id}`, {
      headers: { 'resource-type': params.resourceType },
      params: {
        public: params.public,
        joins: params.joins,
      },
    });
    thunkApi.dispatch(blogsSlice.actions.blogReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const getAllBlogs = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('blogs/getAll', async (params, thunkApi: any) => {
  try {
    const response = await axiosInstance.get('/api/v1/blogs', {
      params,
      headers: { 'resource-type': params.resourceType },
    });
    thunkApi.dispatch(blogsSlice.actions.blogsReceived(response.data.blogs));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

export const updateBlog = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('blog/update', async (params: Blog, thunkApi: any) => {
  try {
    const response = await axiosInstance.patch(
      `/api/v1/blogs/${params.id}/`,
      params,
      {
        headers: { 'resource-type': 'user' },
        params: {
          joins: ['tags'],
        },
      }
    );
    thunkApi.dispatch(blogsSlice.actions.blogReceived(response.data));
    return response.data;
  } catch (error) {
    // If it's no an axios error or if we're a partner, just
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, params.resourceType);
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});


export const createBlog = createAsyncThunk<
  void, // Return type of the payload creator
  any, // First argument to the payload creator
  { rejectValue: Error } // Types for ThunkAPI (the builders)
>('blog/create', async (blog: Blog, thunkApi: any) => {
  const headers = { 'resource-type': 'user' };

  try {
    const response = await axiosInstance.post(`/api/v1/blogs`, blog, {
      headers,
    });
    thunkApi.dispatch(blogsSlice.actions.blogReceived(response.data));
    return response.data;
  } catch (error) {
    if (!axios.isAxiosError(error)) {
      throw error;
    }
    handleErrors(error, thunkApi.dispatch, 'user');
    return thunkApi.rejectWithValue(error?.response?.data);
  }
});

/** ******* MAIN SLICE ******* */
export const blogsSlice = createSlice({
  name: 'blog',
  initialState: blogsAdapter.getInitialState({}),
  // The `reducers` field lets us define reducers
  // and generate associated actions
  reducers: {
    blogReceived: (state, action: PayloadAction<Blog>) => {
      checkStringPayload(action.payload);
      blogsAdapter.upsertOne(state, action.payload);
    },
    blogsReceived: (state, action: PayloadAction<Blog[]>) => {
      checkStringPayload(action.payload);
      blogsAdapter.setAll(state, action.payload);
    },
    removeAllBlogs: (state) => {
      blogsAdapter.removeAll(state);
    }
  },
});

const selectors = blogsAdapter.getSelectors<RootState>((state) => state.blogs);
export const { selectById: selectBlog, selectAll: selectAllBlogs } = selectors;

export default blogsSlice.reducer;
