import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';
import { history, store } from './app/store';
import * as serviceWorker from './serviceWorker';
import {
  initAxiosRequestMiddleware,
  initAxiosResponseMiddleware,
} from './utils/axios/middleware';

initAxiosRequestMiddleware();
initAxiosResponseMiddleware();

render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {/* GoogleReCaptchaProvider not currently used, but maybe there will be a use for it in the future, so leaving it here / installed */}
        {/* <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY || ''}
          > */}
        <App />
        {/* </GoogleReCaptchaProvider> */}
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root-react-app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
