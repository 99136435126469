/**
 * Show some snacks at the top of the page.
 */
import React from 'react';
import { useSnackbar, SnackbarKey } from 'notistack';
import { Box } from '@mui/material';
import { clear, selectSnacks } from './snacksSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const Snacks = function () {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const snacks = useAppSelector(selectSnacks);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    snacks.forEach((snack: any) => {
      const action = (key: SnackbarKey) => (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            cursor: 'pointer',
          }}
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {/* This section adds an X icon to the bottom right,
              giving the user a hint that they can close the 
              snackbar by clicking it. Removing, as it's now 
              inferred. Can close by clicking the snackbar anywhere */}
          {/* <IconButton
            aria-label="dismiss"
            size="small"
            sx={{
              color: 'white',
              position: 'absolute',
              right: '6px',
              bottom: '6px',
              '&:hover': {
                backgroundColor: 'black',
              },
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton> */}
        </Box>
      );
      enqueueSnackbar(snack.message, {
        action,
        variant: snack.variant,
      });
      dispatch(clear(snack.id));
    });
  }, [dispatch, enqueueSnackbar, snacks, closeSnackbar]);

  return <div />;
};

export default Snacks;
