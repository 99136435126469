import React from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import {
  validateFromCookieUser,
  isLoggedInUser,
  attemptUserValidation,
} from '../../store/current-user/currentUserSlice';
import {
  attemptPartnerValidation,
  isLoggedInPartner,
  validateFromCookiePartner,
} from '../../store/current-partner/currentPartnerSlice';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  requiresPartnerAuth,
  requiresUserAuth,
} from '../Router/routes';

export default function TokenValidation() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const userLoggedIn = useAppSelector(isLoggedInUser);
  const partnerLoggedIn = useAppSelector(isLoggedInPartner);

  // validate the user/partner on any page where we could show the sidebar.
  React.useEffect(() => {
    dispatch(attemptPartnerValidation());
    dispatch(attemptUserValidation());
  }, [location.pathname]);

  // Restrict visitor from viewing partner page if they're not logged in yet
  React.useEffect(() => {
    if (!requiresPartnerAuth(location.pathname)) {
      return;
    }
    dispatch(validateFromCookiePartner())
      .unwrap()
      .catch(() => dispatch(push('/partners/sign-in')));
  }, [location.pathname, partnerLoggedIn]);

  // Restrict visitor from viewing user page if they're not logged in yet
  React.useEffect(() => {
    if (!requiresUserAuth(location.pathname)) {
      return;
    }
    dispatch(validateFromCookieUser())
      .unwrap()
      .catch(() => dispatch(push('/users/sign-in')));
  }, [location.pathname, userLoggedIn]);

  return null;
}
