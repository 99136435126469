import React from 'react';
import { useLocation } from 'react-router-dom';
import FacebookPixel from 'react-facebook-pixel';
import RedditPixel from 'react-reddit-pixel';
import ReactPinterestTag from 'react-pinterest-tag';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

/**
 * Given some search query like ?aff_id=12345,
 * Save the ID in a cookie called referrer_id
 *
 * @param searchQuery
 */
const saveAffiliateIdCookie = (searchQuery: URLSearchParams) => {
  const referrerId = searchQuery.get('aff_id');
  if (!referrerId) return;
  cookies.set('referrer_id', referrerId);
};

/**
 * Given some search query like ?utm_source=12345,
 * Save the ID in a cookie called utm_source
 *
 * @param searchQuery
 */
const saveSourceCookie = (searchQuery: URLSearchParams) => {
  const utmSource = searchQuery.get('utm_source');
  if (!utmSource) return;
  cookies.set('utm_source', utmSource);
};

export default function RecordPageViews() {
  const location = useLocation();

  // A custom hook that builds on useLocation to parse the query string
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  React.useEffect(() => {
    FacebookPixel.pageView();
    ReactPinterestTag.pageView();
    RedditPixel.pageVisit();

    saveAffiliateIdCookie(query);
    saveSourceCookie(query);
  }, [location]); // only rerun useEffect if the location changes

  return null;
}
