import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { lighten } from '@mui/material/styles';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import { push } from 'connected-react-router';
import theme from '../../theme/theme';
import constants from '../../theme/constants';
import partnerMenuItems from '../../theme/partnerMenuItems';
import { useAppDispatch } from '../../app/hooks';
import { logoutPartner } from '../../store/current-partner/currentPartnerSlice';

const classes = {
  icon: {
    color: theme.palette.secondary.dark,
  },
  divider: {
    borderBottom: `1px solid ${lighten(theme.palette.secondary.light, 0.6)}`,
  },
};

const HamburgerNav = function ({
  setMobileMenuOpen,
}: {
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [isLoggingPartnerOut, setIsLoggingPartnerOut] = React.useState(false);
  const loggingPartnerOut = () => {
    setIsLoggingPartnerOut(true);
    dispatch(logoutPartner())
      .unwrap()
      .then(() => {
        dispatch(push('/partners/sign-in'));
      })
      .finally(() => setIsLoggingPartnerOut(false));
  };

  useEffect(() => () => setIsLoggingPartnerOut(false));

  const filteredMenuItems = partnerMenuItems.filter(
    (menuItem) => menuItem.displayInMobile !== false
  );

  return (
    <List
      sx={{
        background: 'black',
        width: '100%',
        position: 'absolute',
        top: { xs: constants.navbar.mobileHeight, sm: constants.navbar.height },
        left: 0,
        right: 0,
        color: theme.palette.secondary.dark,
        backgroundColor: lighten(theme.palette.secondary.light, 0.9),
        borderBottom: '10px solid',
        borderBottomColor: theme.palette.secondary.dark,
        boxShadow: '0px 10px 10px rgb(0 0 0 / 30%)',
        [theme.breakpoints.down('sm')]: {
          padding: 0,
        },
      }}
    >
      {filteredMenuItems.map((listItem) => (
        <ListItemButton
          key={listItem.text}
          component={Link}
          to={listItem.url}
          sx={listItem.withDivider ? classes.divider : {}}
          disabled={location.pathname === listItem.url}
        >
          <ListItemIcon sx={classes.icon}>
            {React.createElement(listItem.icon)}
          </ListItemIcon>
          <ListItemText primary={listItem.text} />
        </ListItemButton>
      ))}
      <ListItemButton onClick={() => loggingPartnerOut()}>
        <ListItemIcon sx={classes.icon}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText primary="Log out" />
        {isLoggingPartnerOut && <CircularProgress size="1em" />}
      </ListItemButton>
      <ListItemButton onClick={() => setMobileMenuOpen(false)}>
        <ListItemIcon sx={classes.icon}>
          <ExpandMoreIcon sx={{ transform: 'rotate(180deg)' }} />
        </ListItemIcon>
        <ListItemText primary="Hide Menu" />
      </ListItemButton>
    </List>
  );
};

export default HamburgerNav;
